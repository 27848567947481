.device-setup-modal {
  .modal-dialog.modal-md.modal-dialog-centered {
    .modal-content {
      padding: 32px !important;
    }
  }

  .modal-dialog .modal-content {
    width: 542px;
    min-width: 542px;
    padding: 32px !important;

    .modal-body {
      padding-bottom: 0;

      .device-setup-modal-wrapper {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
      }

      .css-90a3vc-MuiButtonBase-root-MuiRadio-root {
        padding: 0px;
      }

      .css-90a3vc-MuiButtonBase-root-MuiRadio-root.Mui-checked {
        padding: 0px;
      }

      .css-mkl144 {
        padding: 0px;
      }

      .css-mkl144.Mui-checked {
        padding: 0px;
        color: var(--primary_40) !important;
      }

      .MuiFormGroup-root .MuiRadio-root {
        padding: 0px;

        &.Mui-checked {
          color: var(--primary_40);
        }
      }

      .setup-loader {
        color: var(--brand_white);
      }

      & .back-arrow-button {
        display: flex;
        align-items: center;
        gap: 4px;
        color: var(--primary_40);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 4px;
        width: fit-content;
        cursor: pointer;
      }

      & .back-arrow-button-disabled {
        display: flex;
        align-items: center;
        gap: 4px;
        color: var(--primary_88);
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        margin-bottom: 4px;
        width: fit-content;
        cursor: auto;
      }

      .step-icon {
        width: 40px;
        height: 4px;
        border-radius: 40px;
        background: var(--primary_40);
        margin-right: 8px;
      }

      .step-icon-disabled {
        width: 40px;
        height: 4px;
        border-radius: 40px;
        background: var(--primary_88);
        margin-right: 8px;
      }

      .search-container {
        display: flex;
        text-align: center;
        align-items: center;
        width: 100%;

        & .search-icon {
          position: absolute;
          z-index: 9;
          margin: 12px 8px 12px 16px;
        }

        & .search-input {
          display: flex;
          padding: 16px 20px 16px 40px;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;
          border-radius: 10px;
          border: 1.5px solid var(--hGreyscale_56);
          background: var(--brand_white);
          color: var(--greyscale-40);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          width: 100%;

          &:hover {
            background-color: var(--brand_white) !important;
            color: var(--greyscale_40) !important;
            border-color: var(--dark_orange);
          }

          &:focus {
            color: var(--bs-body-color);
            background-color: var(--bs-body-bg);
            border-color: var(--greyscale_buttons_2); // ToDo: Need to Fix
            outline: 0;
          }
        }
      }

      .selected-opt {
        display: flex;
        padding: 4px 8px;
        align-items: center;
        gap: 6px;
        border-radius: 8px;
        background: var(--primary_96);
        color: var(--primary_32);
        font-size: 12px;
        font-style: normal;
        font-weight: 600;
        line-height: 16px;
        margin-left: 8px;
      }

      .discription {
        color: var(--greyscale_56);
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        margin-top: 16px;
      }

      .header-container {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        width: 100%;

        .header-image {
          width: 100%;
          display: flex;
          justify-content: center;
        }
      }

      .stepIcon>img {
        margin-left: 0;
        margin-top: 0;
      }

      .device-setup-modal-title {
        color: var(--greyscale_08);
        font-size: 20px;
        line-height: 28px;
        font-weight: 700;
        text-align: center;
      }

      .device-setup-modal-subTitle {
        color: var(--brand_black);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
      }

      .location-conatiner {
        display: flex;
        max-height: 273px;
        padding: 12px 20px;
        flex-direction: column;
        align-items: flex-start;
        gap: 8px;
        align-self: stretch;
        border-radius: 12px;
        background: var(--greyscale_96);
        color: var(--greyscale_08);
        overflow: auto;

        .location-item {
          display: flex;
          padding: 6px 0px;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          gap: 4px;
          align-self: stretch;
          color: var(--greyscale_08);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
        }
      }

      .css-90a3vc-MuiButtonBase-root-MuiRadio-root.Mui-checked {
        padding: 0px;
      }

      .site-modal-close {
        cursor: pointer;
        color: var(--dark_gray);
        padding: 1.5px;
        font-weight: 600;
        stroke-width: 1;
      }

      .site-modal-close:hover {
        color: var(--button-color-1);
      }

      .site-modal-close:active {
        color: var(--dark_orange);
      }
    }
  }
}
