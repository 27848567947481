.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-field {
  cursor: pointer;
  display: flex;
  width: 180px;
  height: 32px;
  padding: 6px 4px 7px 8px;
  justify-content: space-between;
  align-items: center;
  gap: 41px;
}

.custom-dropdown-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  overflow-y: auto;
  max-height: 150px;
  width: 180px;
  padding: 4px;
  border-radius: 4px;
  margin-top: 2px;
  border: 1px solid rgba(var(--custom_color_27));
  background: var(--brand_white);
  box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.1);
}

.custom-dropdown-item {
  cursor: pointer;
  display: flex;
  padding: 7px 8px 8px 8px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 2px;
  background: var(--brand_white);
  color: var(--greyscale_00);
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: normal;

  &.selected {
    border-radius: 4px;
    background: var(--greyscale_hover1);
    color: var(--brand_primary);
  }
}

.custom-dropdown-item:hover {
  background-color: var(--light_grey);
}
