@import '../../assets/css/utilities';

.marker-cluster:not(:empty) {
  background: var(--primary_40);
  box-shadow: 0px 1px 3px rgb(0 0 0 / 10%), 0px 1px 2px rgb(0 0 0 / 6%);
  border-radius: 24px;
  width: 53px !important;
  height: 36px !important;
  line-height: 36px;
  color: var(--brand_white);
  padding-left: 5px;
  font-weight: 600;
  font-size: toRem(14px);
  line-height: 20px;

  &:before {
    content: url('../../assets/images/icons/MapPin.svg');
    line-height: inherit;
    position: absolute;
    right: 8px;
    top: 2px;
    height: inherit;
    width: inherit;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    display: none;
  }

  div {
    line-height: 36px !important;
    color: var(--brand_white) !important;
    left: 5px !important;
  }
}

.gm-bundled-control-on-bottom {
  .gmnoprint {
    div {
      border-radius: 10px !important;
      height: 64px !important;
      width: 32px !important;

      .gm-control-active {
        width: 32px !important;
        height: 32px !important;

        & > img,
        & > img:hover {
          width: 14px !important;
          height: 14px !important;
          filter: invert(24%) sepia(50%) saturate(6217%) hue-rotate(211deg)
            brightness(83%) contrast(101%);
        }
      }

      div {
        display: none;
      }
    }
  }
}

.create-area-list {
  svg {
    margin-left: 10px;
  }

  .create-area-icon {
    stroke: var(--primary_40);
  }

  .remove-area-icon {
    stroke: var(--error_64);
  }

  .remove-default-area-icon {
    stroke: var(--brand_primary);
  }
}

.marker-info-wrapper {
  max-width: 200px;

  .marker-info-org-title {
    font-weight: 600;
    font-size: toRem(14px);
    line-height: 20px;
  }

  .marker-info-item {
    margin-top: 8px;
    margin-bottom: 8px;
    font-weight: 500;
    font-size: toRem(12px);
    line-height: 16px;

    .marker-info-item-label {
      color: var(--brand_primary);
      margin-bottom: 2px;
    }

    .marker-info-item-value {
      text-align: left;
      color: var(--greyscale_08);
    }
  }
}


.gm-ui-hover-effect {
  transition: background-color 0.3s; 
}

.gm-ui-hover-effect:active {
  background-color: var(--dark_orange);
}

.gm-ui-hover-effect:active span {
  background-color: var(--dark_orange); 
}


.plusIconClass {
  margin-right: 5px;
}