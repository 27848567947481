@import '../../src/assets/css/base';

.term-donction-text {
  margin-right: 5px;
  margin-left: 5px;
  a {
    font-size: 14px;
  }
}

.login-error-container {
  width: 100%;
  display: flex;
  padding: 10px;
  background: var(--error_96);
  border-radius: 12px;
  align-items: flex-start;
  text-align: left;
  justify-content: center;

  .error-text {
    font-style: normal;
    font-weight: 400;
    font-size: toRem(14px);
    color: var(--error_24);
    line-height: 15px;
    margin-left: 5px;
  }
}
.term-privacy-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .block-terms-services{
    text-align: center;
    font-size: toRem(20px);
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }

  .block-policy{
    text-align: center;
    margin-top: -2px;
    font-size: toRem(16px);
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
  }


}

