.site-top-bar .navbar-nav .profile-nav.user-nav-profile .dropdown-menu {
  width: 320px;
  margin-top: 8px;
  background-color: #ffffff;
  border-radius: var(--radius-lg, 8px);
  border: 1px solid rgba(0, 0, 0, 0.08);
  padding: var(--spacing-5, 20px);
  box-shadow: 0 16px 24px rgba(0, 0, 0, 0.56);
}

.user-nav-profile {
  .profile-header {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: var(--spacing-5, 15px);
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
    margin-bottom: var(--spacing-2, 8px);

    .avatar-container {
      margin-bottom: 15px;
    }

    .profile-name {
      margin: 0;
      font-size: 20px;
      font-weight: 700;
      line-height: 28px;
    }

    .profile-email {
      margin: 5px 0 15px 0;
      font-size: 14px;
      color: var(--greyscale_56);
      line-height: 20px;
    }

    .badge-container {
      margin-bottom: 10px;

      .admin-badge {
        display: flex;
        align-items: center;
        border-radius: 15px;
        padding: 4px 12px;
        font-size: 12px;
        line-height: 18px;
        color: var(--text-normal-secondary);

        & .badge-icon {
          margin-right: 5px;
        }
      }
    }
  }

  & .profile-menu {
    display: flex;
    flex-direction: column;
    gap: var(--spacing-2, 8px);

    & .menu-item {
      display: flex;
      align-items: center;
      width: 100%;
      padding: 12px;
      text-align: left;
      border: none;
      border-radius: 5px;
      background-color: transparent;

      &:hover {
        background-color: var(--greyscale_hover1) !important;
      }

      &.active {
        background-color: var(--greyscale_hover1) !important;
      }

      .menu-icon {
        margin-right: 15px;
        font-size: 16px;
        width: 20px;
        color: rgba(0, 0, 0, 0.77);
      }

      .menu-text {
        flex-grow: 1;
        font-size: 14px;
        font-weight: 400;
        color: rgba(0, 0, 0, 0.77);
      }

      .menu-arrow {
        color: rgba(0, 0, 0, 0.77);
      }
    }
  }

  .menu-separator {
    height: 1px;
    background-color: rgba(0, 0, 0, 0.08);
    margin: 4px 0;
  }
}
