
.account-verify {
  .col.input-with-icons {
    .icon-before-input {
      height: 52px;

      svg {
        fill: unset;
        stroke: var(--brand_primary);

        path {
          fill: var(--brand_white);
        }
      }
    }

    .icon-after-input {
      height: 52px;

      svg {
        fill: unset;
        stroke: var(--brand_primary);

        path {
          fill: var(--brand_primary);
        }
      }
    }

    .form-control {
      height: 52px;
    }

    .placeholder-wrapper {
      height: 52px;
      padding: 16px;
    }
  }

  .main-page{
    background-color: var(--primary_96);
    height: calc(100vh - 86px);
    overflow-y: auto;
  }
}

.checked-valid-true {
  stroke: var(--success_48);
  margin-right: 5px;
}

.checked-valid-false {
  stroke: var(--error_64);
  margin-right: 5px;
}

.otpInput {
  width: 1em;
  text-align: center;
  border: 1.5px solid var(--greyscale_88);
  border-radius: 12px;
  padding: 20px 16px;
  min-width: 64px;
  height: 72px;
  margin-right: 12px;
  margin-top: 10px;
  margin-bottom: 10px;

  &:last-child {
    margin-right: 0;
  }

  &.is-invalid {
    background-color: var(--error_96);
    border-color: var(--error_80);
  }
}

.resend-code-link {
  margin-left: 5px;
}
