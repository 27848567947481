
.row {
  .saving-spinner {
    margin-left: 10px;

    .site-spinner {
      padding: 3px;
      margin-right: 5px;
    }
  }

  .saved-tech {
    margin-left: 10px;

    .saved-tech-icon {
      stroke: var(--success_48);
      margin-right: 5px;
    }
  }
}

.icon-error-color {
  fill: var(--error_80);
}

.technicians-error-modal {
  .modal-header {
    .modal-title {
      width: 100%;
      text-align: center;
    }
  }
}
