@import '../../assets/css/base';

.location-selector {
  margin-right: 8px;

  &-toggle {
    &.dropdown-toggle {
      color: var(--greyscale_40);
      display: inline-flex;
      justify-content: space-between;
      align-items: center;
      min-width: 177px;
      padding: 8px 16px;
      border: 1.5px solid var(--greyscale_88);
      font-size: toRem(14px);
      font-weight: 500;
      line-height: 20px;

      &.btn-outline-secondary {
        &.show {
          color: var(--greyscale_40);
          background-color: var(--brand_white);
          border-color: var(--primary_48);
        }
      }

      &::after {
        display: none !important;
      }

      &:hover {
        background-color: var(--brand_white) !important;
        color: var(--greyscale_40) !important;
        border-color: var(--primary_48);
      }
    }
  }

  &-menu {
    &.dropdown-menu {
      min-width: 226px;
      &.show {
        box-shadow: 0px 10px 15px -3px rgba(var(--greyscale-custom1), 0.1),
          0px 4px 6px -2px rgba(var(--greyscale-custom1), 0.05);
        border-radius: 12px;
      }
    }

    &-item {
      border-radius: 12px;
      &.dropdown-item {
        color: var(--greyscale_08);
        font-size: toRem(14px);
        font-weight: 500;
        line-height: 20px;

        &.active {
          color: var(--greyscale_08);
          background-color: var(--brand_white);
          font-weight: 700;
        }

        &:first-child {
          border-top-left-radius: 12px;
          border-top-right-radius: 12px;
        }

        &:last-child {
          border-bottom-left-radius: 12px;
          border-bottom-right-radius: 12px;
        }
      }
    }
  }

  .location-selected {
    &-wrapper {
      display: inline-flex;
      align-items: center;
    }

    &-name {
      margin-left: 10px;
    }
  }
}
