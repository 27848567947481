
.section {
  &-tabs {
    display: inline-flex;
    border-radius: 12px;
    background-color: var(--grayscale_96);
    padding: 5px;
    justify-content: space-between;
    margin-top: 20px;

    &.full-width {
      width: 100%;
    }
  }

  &-tab {
    display: inline-block;
    color: var(--greyscale_56);
    padding: 10px;
    align-items: center;
    cursor: pointer;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      color: var(--primary_08);
    }

    &.active {
      background-color: var(--primary_40);
      color: var(--brand_white);
      border-radius: 12px;
      box-shadow: 0px 1px 3px rgba(var(--greyscale-custom1), 0.1),
        0px 1px 2px rgba(var(--greyscale-custom1), 0.06);

      &:hover {
        background-color: var(--primary_24);
      }
    }

    &-label {
      margin-left: 6px;
    }
  }
}
