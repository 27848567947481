.table-tooltip-container {
  position: relative;

  .hv-tooltip {
    position: absolute;
    width: max-content;
    max-width: 300px;
    padding: 8px 12px;
    border-radius: 8px;
    background-color: var(--component-tooltip-background);
    color: var(--text-on-brand-primary);
    font-size: 13px;
    line-height: 18px;
    word-break: break-word;
    white-space: normal;
    z-index: 20;
  }
}
