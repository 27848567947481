@import '../../assets/css/base.scss';

.tags {
  &-wrapper {
    .tag {
      &-filter {
        &-input {
          display: flex;
          padding: 12px 16px;
          flex-direction: column;
          align-items: flex-start;
          gap: 8px;
          align-self: stretch;
          border-radius: 12px;
          border: 1.5px solid var(--greyscale_88);
          background: var(--brand_white);
          width: 100%;
          height: 44px;
        }
      }
    }

    &-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 6px 10px;
      gap: 6px;
      border-radius: 10px;
      background: var(--grayscale_96);
      color: var(--grayscale_32);
      font-size: toRem(12px);
      font-weight: 600;
      line-height: toRem(16px);
      margin-right: 8px;
    }

    &-label {
      white-space: nowrap;
    }

    &-add-icon,
    &-delete-icon {
      cursor: pointer;
      margin-left: 6px;
    }
  }

  &-list-wrapper,
  &-selected-wrapper {
    // display: inline-flex;
    // justify-content: flex-start;
    // align-items: center;
    display: inline-block;
    white-space: none;
    margin-top: 8px;

    .tag-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 6px 10px;
      gap: 6px;
      border-radius: 10px;
      background: var(--grayscale_96);
      color: var(--grayscale_32);
      font-size: toRem(12px);
      font-weight: 600;
      line-height: toRem(16px);
      margin-right: 8px;
      height: 30px;
      float: left;
    }

    .tag-added-item {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding: 6px 10px;
      gap: 6px;
      border-radius: 10px;
      background: var(--primary_96);
      color: var(--primary_32);
      font-size: toRem(12px);
      font-weight: 600;
      line-height: toRem(16px);
      margin-right: 8px;
      margin-bottom: 8px;
      height: 30px;
      float: left;
    }

    .tag-label {
      white-space: nowrap;
    }

    .tag-add-icon,
    .tag-delete-icon {
      cursor: pointer;
      margin-left: 6px;
      float: left;
    }

    .tag-load-more {
      float: left;
    }

    .tag-add-new {
      float: left;
      &-label {
        color: #0052cc;
        font-size: toRem(12px);
        font-weight: 600;
        line-height: toRem(16px);
      }
    }
  }
}
