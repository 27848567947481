 @import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;1,300;1,400;1,700&display=swap');

 .account-settings{
  .breadcrumb-container{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    align-items: center;
  }
  .setting-close{
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .account-custom-class{
    
  .is-invalid{
    .custom-input-class{
    margin-bottom: 0px;
    }
    
  }
  .placeholder-wrapper {
    left: 5px !important;
}
    label{
      color: var(--text-normal-secondary);
      text-align: left;
      font-size: 14px;
      line-height: 20px;
    }
    .custom-input-class{
      // height: 32px;
      border-radius: 4px;
      border: 1px solid var(--input-outline-default);
      background: rgba(0, 0, 0, 0.00);
      padding: 0px 0.5rem;
      margin-bottom: 12px;
      font-weight: 400;
      line-height: 20px;

    }
    .custom-input-class:disabled{
      background-color: var(--bs-secondary-bg);
    }
    .modal-form .error {
    margin-left: 1rem !important;
    }
  }
  .session-title{
    color: var(--Text-Normal-Secondary, rgba(0, 0, 0, 0.77));
    font-size: 13px;
    line-height: 20px;
    display: flex;
    align-items: center;
  }
  .session-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
    .license-tab{
    display: flex;
    gap: 30px;
    .nav-item {
      display: inline-flex;
      align-items: flex-start;
      gap: 10px;
      .nav-link {
        padding: 12px 0px;
        color: rgba(var(--custom_color_24));
        text-align: center;
        font-size: toRem(16px);
        font-weight: 400;
        line-height: toRem(22px);
        border: none;

        &.active {
          border: none;
          color: var(--brand_black);
          font-weight: 700;
          border-bottom: 2px solid var(--dark_orange);
          &:hover {
            border-bottom: 2px solid var(--dark_orange);
          }
        }
        &:active,
        &:hover {
          border: none;
        }
      }
    }
  }
  @media (max-width: 767px) {
  .account-settings .account-custom-class label {
    margin-left: 2px;
  }
}

/* Custom styles for Toast */
.custom-toast-container {
  position: fixed;
  top: 35px;
  right: 0px;
  width: 400px;
  padding: var(--Spacing-5, 20px);
  z-index: 9999;
}
.custom-toast {
  display: flex;
  width: 100%;
  padding: var(--Spacing-5, 20px);
  align-items: flex-start;
  gap: var(--Spacing-2, 8px);
  border-radius: 8px;
  background-color:  var(--text-normal-primary) !important;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.custom-toast .icon {
  font-size: 20px;
}
.custom-toast .message {
  color:  #FFF;
  line-height:  20px;
}
.toast-container .toast {
  border-color:black !important;
  min-height: 0px !important;
}
}
@media (max-width: 767px) {
    .account-settings .custom-toast-container {
        top: 40px;
    }
}
.account-profile-icon {
  position: relative;
  .edit-pencil-icon {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    width: 32px;
    height: 32px;
    left: 56px;
    top: 56px;
    background: var(--primary_40);
    border-radius: 10px;

    label {
      height: 100%;
      width: 100%;
      text-align: center;
      cursor: auto;
    }

    input {
      display: none;
    }

    svg {
      stroke: var(--brand_white);
    }
  }
  .disabled-icon {
    background: var(--greyscale_88);
  }
}

.organization-settings {
  .form-submit-main {
    text-align: end;
    margin-top: 66px;
  }

  .range-field-container {
    text-align: left;
  }
}

.update-password{
  .row-text-left{
    text-align: left !important;
  }
  .d-flex{
    display: flex;
  }
  .row-title{
    color: var(--brand_black);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px;
  }
  .password-hints {
    color: var(--greyscale_08);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px;
}
  .col.input-with-icons {
    .icon-before-input {
      height: 52px;

      svg {
        fill: unset;
        stroke: var(--brand_primary);

        path {
          fill: var(--brand_white);
        }
      }
    }
  
    .icon-after-input {
      svg {
        fill: unset;
        stroke: var(--greyscale_56);

        path {
          fill: var(--greyscale_56);
        }
      }
    }

    .form-control {
      height: 52px;
    }

    .placeholder-wrapper {
      height: 52px;
      padding: 16px;
    }
  }

  .left-to-icon{
    margin-left: 8px;
  }
  .m-t-6{
    margin-top: 6px;
  }
  .m-t-8{
    margin-top: 8px;
  }
  .m-t-50{
    margin-top: 50px;
  }
  .org-btn{
    max-width: 150px;
    overflow:hidden;
    white-space:nowrap;
    text-overflow:ellipsis;
  }
  .m-t-20{
    margin-top: 20px !important;
  }
  .m-t-12{
    margin-top: 12px !important;
  }

  .set-to-right{
    justify-content: right;
    display: flex;
  }
  .m-t-40{
    margin-top: 40px;
  }
}
 @media (max-width: 767px) {
    .flex-wrap-session {
      margin-left: 1px;
    }
}
.profile-btn .btn-primary{
  border-radius: 6px !important;
}


.toggle-field-one {
  display: flex;
  gap: 12px;
  border-radius: 12px;

  &.toggle-bg {
    background: transparent;
    padding: 5px;
    flex-direction: row;
  }

  .toggle-field-container {
    display: flex;
    justify-content: space-between;
    padding: 0px;

    .toggle-field-label {
      font-style: normal;
      font-weight: 500;
      font-size: toRem(14px);
      line-height: 20px;

      &.firmware-label {
        color: var(--text_color);
      }
    }
  }

  .toggle-switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
    margin-top: 5px;

    input {
      opacity: 0;
      width: 0;
      height: 0;

      &:checked + .toggle-slider {
        background-color: var(--primary_40);
      }

      &:focus + .toggle-slider {
        box-shadow: 0 0 1px var(--primary_40);
      }

      &:checked + .toggle-slider:before {
        -webkit-transform: translateX(26px);
        -ms-transform: translateX(26px);
        transform: translateX(26px);
      }
    }
  }

  .toggle-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--greyscale_88);
    -webkit-transition: 0.4s;
    transition: 0.4s;

    &:before {
      position: absolute;
      content: '';
      height: 20px;
      width: 20px;
      left: 2px;
      top: 2px;
      background-color: var(--brand_white);
      transition: 0.4s;
    }

    &.round {
      border-radius: 34px;

      &:before {
        border-radius: 50%;
      }
    }
  }
}
.input-with-icons .icon-after-input{
    height: 40px !important;
    right: 10px !important;
  }
  .input-with-icons .form-control {
    text-indent: 1px !important;
}