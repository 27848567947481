.icon-container {
    position: relative !important;
}
.overlay-icon {
    position: fixed !important;
    right: 5px !important;
    bottom: 5px !important;
    z-index: 10001 !important;
}
.chatbotIcon{
    height: 80px;
    width: 80px;
    cursor: pointer;
}