.success-container{
  text-align: center !important;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.success-title{
  color: var(--greyscale-greyscale-08, #111418);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px; /* 140% */
  width: 335px;
}

.success-subtitle{
  color: var(--greyscale-greyscale-40, #566376);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px; /* 150% */
}
.setting-label{
  color: var(--greyscale-greyscale-56, #7D8BA1);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px; /* 133.333% */
}

.head-container{
  display: flex;
  justify-content: space-between;
  
  .header-title {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 700;
    line-height: 32px; /* 133.333% */
  }
}

.ldapEnabledClass {
  input {
    &:checked + .toggle-slider {
      background-color: var(--primary_40) !important;
    }
  }
}

.authenticateLDAPStyle {
  margin-bottom: 5px;
}
