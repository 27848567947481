.custom-select-container {
  .MuiOutlinedInput-root {
    padding: 16px 3px;
    color: var(--greyscale_08);
    border: 1.5px solid var(--greyscale_88);
    &:focus,
    &:active,
    &:hover {
      border-color: var(--brand_primary) !important; // ToDo: Need to Fix
    } // Updated padding

    .MuiInputBase-input {
      font-size: 0.875rem !important; // Updated placeholder font size
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  .MuiInputBase-root-MuiOutlinedInput-root.Mui-focused
    .MuiOutlinedInput-notchedOutline {
    border: none !important;
  }
  .MuiPaper-root {
    margin: 0px !important;
  }
  .filtered-text {
    color: var(--brand_primary) !important;
  }
  .MuiTypography-root {
    font-size: 0.875rem !important; // Ensures consistency for placeholder
  }
}
