
.edit-customer-user-modal {
  .modal-header {
    padding-bottom: 0;
  }

  .modal-title {
    display: flex;
    flex-direction: column;
    align-items: start;
  }

  .status-info,
  .btn {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
}

.plusIconClass {
  margin-right: 5px;
}
