@import '../../assets//css/base.scss';

.timezone-input-wrapper {
  .MuiFormControl-root {
    .MuiOutlinedInput-root {
      border-radius: 12px;
    }
  }
}

.main-select {
  color: var(--greyscale_56);
}
