.table-responsive {
  position: relative;

  .resizable-table.table thead,
  .resizable-table.table tbody,
  .resizable-table.table tfoot,
  .resizable-table.table tr,
  .resizable-table.table td,
  .resizable-table.table th {
    height: 48px;
  }

  .resizable-table.table {
    min-width: 1522px;
    border: 1px solid rgba(var(--custom_color_26));
    border-radius: 8px;
    border-spacing: 0px;

    thead {
      height: 46px !important;
      tr {
        height: 46px !important;
      }

      th {
        height: 46px;
        padding: 0px 0px 0px 10px;
        gap: 10px;
        background: var(--custom_color_22);
        color: var(--text_color);
        align-items: center;
        text-align: start;
        vertical-align: middle;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        &:first-child {
          border-top-left-radius: 8px;
        }

        &:last-child {
          border-top-right-radius: 8px;
        }

        .without-border {
          width: 100%;
          gap: 4px;
          height: 19px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .with-border {
          width: 100%;
          gap: 4px;
          height: 19px;
          border-right: 1px solid var(--custom_color_23);
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
    tbody {
      tr {
        background: initial;
        height: 48px;
        padding: 0px 20px 0px 12px;
        border-style: solid solid solid solid;
        border: 1px solid var(--custom_color_23);
        cursor: auto;

        .hover-icons {
          visibility: hidden;
        }
        .truncated-device-name {
          display: flex;
          align-items: center !important;
        }

        &:hover {
          background-color: rgba(var(--custom_color_18));

          .hover-icons {
            cursor: pointer;
            visibility: visible;
            // display: flex;
            align-items: center;

            svg {
              padding: 1px;
            }
          }
        }

        th,
        td {
          height: 48px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;

          &.device-name-td {
            display: flex;
            align-items: center;

            .edit-name-container {
              margin-top: 0px;
            }
          }
          .searchStyles {
            text-overflow: ellipsis;
            white-space: nowrap;
            overflow: hidden;
            max-width: 90%;
          }
        }

        td {
          border-radius: unset;
          padding: 0px 10px 0px 10px;
          &:last-child {
            padding-right: 20px;
          }
        }

        &:last-child {
          border: none;

          td {
            border: none;
          }

          .loader-container {
            text-align: center;
            background-color: var(--brand_white);
            height: calc(100vh - 550px);

            .site-spinner {
              padding: 2.5px;
            }

            .loading-text {
              color: rgba(var(--custom_color_28));
              text-align: center;
              font-size: toRem(16px);
              font-weight: 400;
              line-height: toRem(22px);
              margin-top: 10px;
            }
          }

          .no-device-container {
            text-align: center;
            background-color: var(--brand_white);
            height: calc(100vh - 550px);

            .no-device-content {
              display: flex;
              flex-direction: column;
              text-align: center;
              font-family: "Noto Sans";
              font-style: normal;
              color: rgba(var(--custom_color_28));
              padding: 0px;

              .no-device-heading {
                font-size: toRem(20px);
                font-weight: 700;
                line-height: normal;
              }
              .no-device-text {
                font-size: toRem(16px);
                font-weight: 400;
                line-height: toRem(22px);
              }
            }
          }
        }
      }
    }
  }
}

.table-responsive {
  margin-top: 8px;
  overflow-y: hidden;
}

.pagination-container {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 20px;
  align-self: stretch;
  margin-top: 16px;
  flex-wrap: wrap;

  .go-to-page {
    color: rgba(var(--custom_color_17));
    font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;

    .input-page {
      display: flex;
      height: 32px;
      align-items: center;
      gap: 8px;
      border-radius: 4px;
      border: 1px solid rgba(var(--custom_color_19));
      background: var(--brand_white);
      & .page-input {
        padding: 0px 8px;
        color: rgba(var(--custom_color_17));
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
        background: initial;
        width: 100%;
        max-width: 50px;
        min-width: 50px;
        height: 30px;
        border-radius: none;
        border: none;

        &:hover {
          background: initial !important;
          color: var(--greyscale_40) !important;
          border-color: var(--dark_orange);
        }

        &:focus {
          color: var(--bs-body-color);
          background-color: var(--bs-body-bg);
          border-color: var(--greyscale_buttons_2); // ToDo: Need to Fix
          outline: 0;
        }
      }
      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }

      /* Firefox */
      input[type="number"] {
        -moz-appearance: textfield;
      }
    }
    .Mui-selected {
      background-color: var(--brand_primary);
    }
  }
  .total {
    color: rgba(var(--custom_color_17));
    font-family: "Noto Sans";
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    display: flex;
    gap: 4px;
  }
  .Mui-selected {
    background-color: var(--brand_primary);
    color: var(--brand_white) !important;
    &:hover {
      background-color: var(--dark_orange_1);
      color: var(--brand_white);
    }
  }
  .MuiPaginationItem-rounded {
    display: flex;
    min-width: 24px;
    height: 24px;
    padding: 3px 0px;
    justify-content: center;
    align-items: center;
    border-radius: 4px;
    color: rgba(var(--custom_color_17));
    text-align: center;
    font-family: "Noto Sans";
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 18px;
  }
}
