@import '../../assets/css/base.scss';

.context-menu {
  .MuiPaper-root {
    border-radius: 12px;
    box-shadow: 0px 4px 6px -2px rgba(var(--greyscale-custom1), 0.05),
      0px 10px 15px -3px rgba(var(--greyscale-custom1), 0.1);

    .context-menu-list {
      padding: 0;
      li {
        padding-right: 12px;
        padding-left: 12px;

        &:first-child {
          padding-top: 18px;
        }

        &:last-child {
          padding-bottom: 18px;
        }

        .menu-item {
          &-icon {
            height: toRem(20px);
            width: toRem(20px);
            min-width: toRem(20px);
            margin-right: 8px;
          }

          &-text span {
            color: var(--greyscale_40);
            font-size: toRem(14px);
            font-weight: 500;
            line-height: 20px;
            overflow-wrap: break-word;
            text-wrap: wrap;
          }
        }
      }
    }
  }
}
