@import '../../assets/css/base.scss';

.dropdown {
  position: relative;

  .search-dahboard-camera {
    align-items: center;
    box-sizing: border-box;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 9px;
    gap: 8px;
    border-radius: 12px;
    border: 1.5px solid var(--greyscale_88);
    background: var(--brand_white);
    margin-top: -7px;

    &:hover {
      border: 2px solid var(--primary_48);
    }

    .device-selected {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 110px;
    }
  }

  .custom-dropdown-toggle {
    color: var(--primary_40);
    font-size: toRem(14px);
    font-weight: 600;
    text-transform: capitalize;
    cursor: pointer;

    &.isSearch {
      color: var(--greyscale_40);
    }
  }

  .custom-dropdown-icon {
    margin-left: 6px;
    font-weight: 600;
    stroke-width: 4px;
  }

  .dropdown-menu {
    border: 0;
    box-shadow: 0px 10px 15px -3px rgba(var(--greyscale-custom1), 0.1),
      0px 4px 6px -2px rgba(var(--greyscale-custom1), 0.05);
    border-radius: 12px;
    min-width: 160px;
    padding-top: 0;
    padding-bottom: 0;

    .dropdown-item {
      color: var(--greyscale_08);
      font-size: toRem(14px);
      font-weight: 500;
      padding-top: 8px;
      padding-bottom: 8px;

      &:hover {
        background-color: var(--grayscale_96);
      }
    }

    .secondary-dropdown-container {
      width: 246px;
      padding: 10px 20px;

      &.device-filter {
        width: 300px;
      }
    }
  }
}
