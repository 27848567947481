@import 'utilities';

@media screen and (max-width: 767px) {
  .list-block-item.card .card-body .row {
    margin-bottom: 0 !important;
  }

  .list-block-item.card .card-body .row:not(:first-child) > * {
    margin-bottom: 24px;
  }

  .list-block-item.card .card-body .avatars-list .row > * {
    margin-bottom: 0;
  }

  .invitationCodeOtp input {
    min-width: 49px;
    height: 64px;
  }

  .help-link {
    font-style: normal;
    font-weight: 600;
    font-size: toRem(14px);
    line-height: 20px;
  }

  .remove-padding-responsive {
    padding-left: 0;
    padding-right: 0;
  }

  .main-page-content {
    padding-bottom: 0;
    padding-left: 0;
    padding-right: 0;
  }

  .main-card {
    border-radius: 32px 32px 0px 0px;
  }

  footer,
  .account-verify .site-top-bar .navbar-toggler {
    display: none;
  }

  /* Site Modal CSS */
  .modal {
    overflow: hidden;
    padding: 0 !important;
  }

  .modal.show .modal-dialog {
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    margin-top: 0;
    align-items: end;
    min-height: 100%;
    max-width: 100%;
  }

  .modal-content {
    border-radius: 0px 0px 0px 0px !important;
    min-height: 100.5vh;
    height: 100.5vh;
    padding: 20px;
    overflow-y: auto;
  }

  .modal-form .form-control {
    margin-bottom: 16px;
  }
  .discovered-devices .modal-body{
    height: 100%;
    min-height: auto;
    max-height: 100%;
  }
  .discovered-devices .modal-content{
    min-height: calc(100vh - 104px);
    max-height: calc(100vh - 104px);
  }
  .add-devices-mediaQuery .modal-body{
    height: 100%;
    min-height: auto;
    max-height: 100%;
  }
  .add-devices-mediaQuery .modal-content{
    max-height:calc(100vh - 104px);
   }
  .modal {max-height: 100vh;
     .modal-dialog { 
      .modal-content { 
        .modal-body {
           max-height: calc(100vh - 104px);
             } } } }
  .modalBackButton .modal-content .modal-header{
    flex-wrap: wrap; padding-top: 0;
  }
  .modalBackButton .modal-content .modal-header .site-modal-close{
    display: none;
  }
  .modal-content .modal-header .breadcrumbsResponsive{display: none !important;}
  .modalBackButton .modal-content .modal-header .breadcrumbsResponsive {
    padding-left: 0; padding-top: 0; display: block !important;
  }
  .discovered-devices .modal-content .modal-title{
    font-size: 32px !important; line-height: 1.25 !important; width: 100%;
  }
  .discovered-devices .device-container {
    min-height: initial;
    height: 100%;
    display: flex;
    flex-direction: column;
  }
  .discovered-devices .device-container .footer-container{
    margin-top: auto;
  }
  .discovered-devices .device-container .loading-screen{
    min-height: 480px;
  }
  .discovered-devices .device-container .footer-container.mobileView{display: block;}
  .footer-container.mobileView .right-section {
    display: none !important;
  }
  .footer-container.desktopView .left-section {
    display: none !important;
  }
  .footer-container.desktopView .right-section {
    width: 100%;
    justify-content: space-between;
  }
  .noBackDrop{display: none;}
  .sub-site-top-bar{
    height: 48px !important;
  }
  .sub-site-top-bar .tab-btns .btn{
    padding: 5px 25px;
  }
  .sub-site-top-bar .tab-btns .btn span {
    vertical-align: text-bottom;
  }
  .userTable, .licenses-table{
    td,th{white-space: nowrap !important; max-width: initial !important;}
  }
  .settingsTabs .section-tab {
    flex: auto;
    justify-content: center;
  }
  .detalsSecButton{ flex-direction: column-reverse;}
  .detalsSecButton button{display: block; width: 100% !important; margin-left: 0;}
  .detalsSecButton button + button{margin-bottom: 10px;}
  button.removeMobileview{display: none;}
  .section-tab.active{
    padding: 8px 16px 8px 16px;
    width: auto;
    height: 40px;
  }
  .settings-info-block-heading {
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    display: flex;
    align-items: center;
    color: var(--body_color);
    text-align: left !important;
}
.settings-info-block-content {
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  color: var(--body_color);
}
.settings-block {
  margin-bottom: 16px;
}
.card-header .card-title {
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 28px;
  color: var(--brand_black);
  margin-left: 2px;
  margin-top: 36px
}
.col .license-prop-value {
  display: inline-flex;
  color: var(--greyscale_08);
  font-size: 1rem;
  line-height: 1.15rem;
  align-items: center;
}
.col .license-prop-value .value-wrapper {
  margin-left: 6.5px;
}
.col .license-prop {
  color: var(--greyscale_56);
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.15rem;
}
.col .license-action {
  color: var(--primary_40);
  text-align: left;
  cursor: pointer;
  font-weight: 500;
  font-size: 1rem;
}
.settings-info-block-content.expiry-color{
  color:var(--warning_48);
}
.device-info-section{
  padding: 16px !important;
}
.tab-sub-title {
  color: var(--greyscale_56);
}
.modal-dialog.modal-md.modal-dialog-centered {
  // display: block;
  // display: flow-root;
  // margin-top: 58px;
     position: relative;
}
.modal-body-responsive{
  max-height:100%;
}
.discovered-devices .device-container .filter-container .filter-tabs .nav-item button{
  margin-bottom: 9px;
}
.discovered-devices .device-container .footer-container .left-section .device-details .device-name{
    font-weight: 600;
    width:100% !important;
    white-space: normal !important;
}
.ctn-box.innner {
  max-height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.deviceIconMobile{
  border: 1px solid var(--greyscale_80);
  border-radius: 4px;
  cursor: pointer;
  padding: 8px;
  justify-content: space-between;
}
.label-title{
  color: var(--greyscale_40);
  font-size: 12px;
  font-style: Noto Sans;
  line-height: 16px;
}
.applicationAppMobile{
  margin-top: 14px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
  padding-left: 0px;
  padding-right: 0px;
 }
.appActiveOnCloud{
  border:1px solid var(--primary_40);
  background-color:var(--primary_96);
  cursor: pointer;
}
.appActiveWithoutOnCloud{
  border:1px solid var(--primary_40);
  background-color:var(--primary_96);
  cursor: default;
}
 
.status-indicator {
  margin-right: 0.375rem;
  height: 0.625rem !important;
  width: 0.625rem !important;

  &.status {
    &-online {
      color: var(--success_48);
    }

    &-claiming {
      color: var(--primary_48);
    }

    &-entered {
      color: var(--warning_64);
    }

    &-offline {
      color: var(--error_112);
    }

    &-ready-to-claim {
      color: var(--greyscale_40);
    }
  }
}
.camera-details-mobile {
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0;
  align-self: stretch
}
.accordian .accordion-item .camera .camera-details {
  display: flex;
  padding: 16px 0px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 16px;
  flex: 1 0;
  align-self: stretch;
  overflow-x: visible !important
}
.firmware-update {
  display: flex;
  padding: 7px 12px;
  justify-content: center;
  gap: 8px;
  border-radius: 8px;
  border: 2px solid rgba(var(--greyscale-custom4), 0.1);
  background: var(--error_96);
  margin-left: 1px;
  margin-top: 16px;
}
.accordian .accordion-item .camera {
  margin-top: 3px;
}
.device-name-mobile{
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  color: var(--greyscale_08);
}
.site-top-bar .navbar-nav .nav-link {
  color: var(--brand_white);
  font-family: 'Noto Sans';
  font-style: normal;
  font-weight: 500;
  font-size: toRem(18px);
  line-height: 20px;
  padding: 0px 0px;
}
.modal-dialog .modal-content {
  background: var(--brand_white);
  border-radius: 16px;
  padding: 24px;
  border: 0;
}
.page-title.text-start.setting-title.col {
  margin-top: 65px;
}
.info-timezone .message-info {
  margin-left: 5px;
  color: var(--body_color);
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 500;
  line-height: 13px;
  margin-top: 10px;
}
.mobile-text-box {
  height: auto !important;
  width: 45px !important;
  font-size: 21px !important;
 }
  button.addlicense-btn {
    width: 85px !important;
    height: 47px;
  }
}