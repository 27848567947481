.section-header-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.section-left {
  display: flex;
  flex-direction: column;

  .site-breadcrumbs .breadcrumb-item a {
    font-size: 0.75rem;
    font-weight: 500;
  }

  .site-breadcrumbs .breadcrumb-item a:hover {
    text-decoration: underline;
  }

  .site-breadcrumbs .breadcrumb-item.active a {
    font-size: 0.75rem;
  }

  .site-breadcrumbs .breadcrumb-item.active a:hover {
    text-decoration: none;
  }

  .site-breadcrumbs .breadcrumb-item + .breadcrumb-item::before {
    content: '';
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: 5px 5px 0px 5px;
    background-image: url('../../assets/images/breadcrumb-divider.svg');
    background-size: contain;
    background-repeat: no-repeat;
  }

  nav ol {
    margin-bottom: 0px;
  }
}

.title {
  font-size: 24px;
  font-weight: 700;
  color: var(--brand_black);
  margin: 0;
  cursor: pointer;
}

.close {
  cursor: pointer;
}
