@import '../../assets/css/base';
@import '../../assets/css/styles';

.organization-bg {
  width: 100%;
  height: 100vh;
  background: linear-gradient(
                  to top,
                  white 0%,
                  white 50%,
                  var(--backgroundColor) 0%,
                  var(--backgroundColor) 50%
  );

  &.progress {
    background: var(--brand_white)
  }
}
.modal-dialog.modal-md.modal-dialog-centered {
  .modal-content {
    padding: 10px 25px 25px 25px !important;
  }
  .modal-header {
    padding-bottom: 5px !important;
    .modal-title {
      max-width: 470px;
    }
  }
}
.send-invitation-partner {
  .modal-header {
    .modal-title {
      width: 100%;
      text-align: center;
    }
  }
  @media (min-width: 641px) {
    .modal-dialog .modal-content {
      max-width: 400px !important;
    }
  }
}
// @media  (min-width: 641px) {
//   .modal-dialog .modal-content {
//     max-width: 400px;
//   }
// }

.location-map {
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.copy-icon {
  color: rgba(var(--greyscale-custom3), 0.8);

  &:active {
    color: var(--brand_white);
  }
}

.copy-icon-custom {
  color: rgba(var(--brand_black), 0.8);

  &:active {
    color: var(--brand_white);
  }
}

.org-remove-invite-status {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}

.sub-title-modal {
  color: var(--greyscale_56);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;

  &.sent-invite {
    margin-left: 20px;
    margin-right: 20px;
    text-align: center;
  }

  &.error-m {
    text-align: left;
  }
}
.sub-title-remove {
  color: var(--greyscale_40);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.remove-btn-conatiner {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  margin-top: 25px;

  .btn-remove {
    margin-right: 10px !important;
  }
}

.partner-email-not-verified {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 4px;

  .email-error-message {
    margin-left: 5px;
    font-size: toRem(12px);
    font-weight: 500;
    line-height: 16px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--error_64);
    margin-top: 4px;
  }
}

.textbox {
  width: 100%;
  font-style: normal;
  font-weight: 500;
  font-size: toRem(16px);
  line-height: 20px;
  color: var(--greyscale_40);
  //margin-left: 10px;
  text-align: left;

  .form-control {
    padding: 12px 16px;
    background-color: var(--brand_white);
    color: var(--greyscale_08);
    border: 1.5px solid var(--hGreyscale_56) !important;
    height: 44px;
    border-radius: 12px;
    font-weight: 500;
    font-size: 0.875rem;
    line-height: 20px;
    box-shadow: unset;

    &.email-error {
      background-color: var(--error_96);
      border: 1px solid var(--error_80);
      color: var(--error_64);
    }
  }
  .form-control:active,
  .form-control:focus
  {
    border-color: var(--greyscale_buttons_2) !important;
  }
  .form-control:hover{
  border-color: var(--brand_primary) !important;
  }
}

.device-claimed-status {
  margin-top: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  .claimed-view {
    background: var(--success_64);
    border-radius: 50%;
    width: 96px;
    height: 96px;
    display: flex;
    justify-content: center;
    align-items: center;

    &.reject {
      background: var(--error_80);
    }
    &.warning {
      background: var(--warning_80);
    }
    .image-tick {
      height: 40px;
      width: 40px;
    }
  }
}

.org-detail-assign {
  text-align: left;
  font-weight: 400;
  font-size: toRem(12px);
  line-height: 20px;
  color: var(--greyscale_08);
  margin-bottom: 16px;
  margin-left: 15px;
}

.org-detail-partner-list {
  margin-bottom: 8px;
  font-weight: 500;
  text-align: left;

  .org-detail-partner-title {
    font-size: toRem(12px);
    line-height: 16px;
    color: var(--greyscale_40);
    text-align: left;
  }

  .org-detail-partner-content {
    font-size: toRem(14px);
    line-height: 20px;
    color: var(--brand_black);
  }
}

.org-detail-partner-tech-title {
  font-weight: 600;
  font-size: toRem(16px);
  line-height: 20px;
  color: var(--greyscale_08);
  margin-bottom: 16px;
  text-align: left;
}

.device-detail-graph-conainer {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: auto;
  overflow: auto;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    justify-content: flex-start;
  }

  &::-webkit-scrollbar {
    display: none !important;
  }

  .device-details {
    width: 33%;
    background-color: var(--brand_white);
    height: 100%;
    border-radius: 12px;
    margin-right: 10px;
    border: 1px solid var(--greyscale_88);

    @media screen and (max-width: 1000px) {
      width: 98%;
      margin-right: 0px;
    }

    .header-layout {
      padding-top: 25px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-left: 15px;
      margin-right: 15px;

      .devices-label {
        color: var(--brand_black);
        font-size: toRem(20px);
        font-style: normal;
        font-weight: 700;
        line-height: 28px;

        @media screen and (max-width: 1000px) {
          font-size: toRem(16px);
        }
      }

      .manage-devices {
        color: var(--primary_40);
        font-size: toRem(14px);
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        cursor: pointer;

        @media screen and (max-width: 1000px) {
          font-size: toRem(12px);
        }
      }
    }

    .camera-layout {
      margin-top: 25px;
      margin-left: 20px;
      border-radius: 12px;
      background: var(--primary_96);
      height: 50px;
      width: 50px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;

      @media screen and (max-width: 1000px) {
        height: 35px;
        width: 35px;
      }

      .img-size {
        height: 25px;
        width: 25px;

        @media screen and (max-width: 1000px) {
          height: 18px;
          width: 18px;
        }
      }
    }

    .device-count-layout {
      margin-top: 10px;
      margin-left: 20px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;

      .device-count {
        color: var(--grayscale_24);
        font-size: toRem(56px);
        font-style: normal;
        font-weight: 600;
        line-height: 56px;
        letter-spacing: -1.68px;
      }

      .total-device-layout {
        margin-left: 5px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        .total-devices {
          color: var(--grayscale_24);
          font-size: toRem(20px);
          font-style: normal;
          font-weight: 600;
          line-height: 28px;
        }

        .active-devices {
          color: var(--grayscale_24);
          font-size: toRem(12px);
          font-style: normal;
          font-weight: 400;
          line-height: 15px;
        }
      }
    }

    .seprator {
      margin-top: 15px;
      background: var(--seprator_device);
      height: 1px;
      margin-left: 15px;
      margin-right: 15px;
    }

    .bottom-container {
      margin-bottom: 10px;
      margin-top: 10px;
      margin-left: 15px;
      margin-right: 15px;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding-bottom: 20px;
      padding-top: 10px;

      .device-row-left {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;

        .label-name {
          margin-left: 10px;
          color: var(--text_color);
          font-size: toRem(14px);
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .status-icon {
          width: 20px;
          height: 20px;
          color: var(--success_48);

          &.update-available {
            color: var(--warning_48);
          }

          &.no-devices {
            color: var(--greyscale_56);
          }
        }
      }

      .device-row-right {
        width: 48%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .label-name {
          color: var(--text_color);
          font-size: toRem(14px);
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }

        .status-icon {
          width: 20px;
          height: 20px;
          color: var(--success_48);
        }
      }
    }
  }

  .device-uptime-graph {
    margin-left: 10px;
    width: 67%;
    background-color: var(--brand_white);
    height: 100%;
    border-radius: 12px;
    border: 1px solid var(--greyscale_88);

    @media screen and (max-width: 1000px) {
      width: 98%;
      margin-left: 0px;
      margin-top: 10px;
    }
  }
}

.licenses-location-account-container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: auto;
  overflow: auto;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    justify-content: flex-start;
  }

  .licenses-details {
    width: 33%;
    background-color: var(--brand_white);
    height: 100%;
    border-radius: 12px;
    margin-right: 10px;
    // border: 1px solid var(--greyscale_88);

    @media screen and (max-width: 1000px) {
      width: 98%;
      margin-right: 0px;
    }

    .header-layout {
      padding-top: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-left: 25px;
      margin-right: 25px;

      .header-label {
        color: var(--brand_black);
        font-size: toRem(20px);
        font-style: normal;
        font-weight: 700;
        line-height: 28px;

        @media screen and (max-width: 1000px) {
          font-size: toRem(16px);
        }
      }

      .manage-label {
        color: var(--primary_40);
        font-size: toRem(14px);
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        cursor: pointer;

        @media screen and (max-width: 1000px) {
          font-size: toRem(12px);
        }
      }
    }

    .section-licenses {
      margin-top: 10px;
      border: 1px solid var(--greyscale_88);
      background-color: var(--brand_white);
      padding: 9.5px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;
      border-radius: 8px;

      .label-name {
        width: 12%;
        text-align: center;
        margin-left: 5px;
        color: var(--grayscale_16);
        font-size: toRem(24px);
        font-style: normal;
        font-weight: 600;
        line-height: 32px;

        &.highlight-bg {
          background: var(--warning_96);
          padding-left: 5px;
          padding-right: 5px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: center;
          color: var(--warning_24);
          border-radius: 8px;
        }
      }

      .label-value {
        width: 88%;
        text-align: left;
        margin-left: 10px;
        color: var(--greyscale_48);
        font-size: toRem(16px);
        font-style: normal;
        font-weight: 400;
        line-height: 32px;
      }
    }
  }

  .location-details {
    margin-left: 10px;
    width: 33%;
    background-color: var(--brand_white);
    height: 100%;
    border-radius: 12px;
    margin-right: 10px;
    // border: 1px solid var(--greyscale_88);

    @media screen and (max-width: 1000px) {
      width: 98%;
      margin-right: 0px;
    }

    .header-layout {
      padding-top: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-left: 25px;
      margin-right: 25px;

      .header-label {
        color: var(--brand_black);
        font-size: toRem(20px);
        font-style: normal;
        font-weight: 700;
        line-height: 28px;

        @media screen and (max-width: 1000px) {
          font-size: toRem(16px);
        }
      }

      .manage-label {
        color: var(--primary_40);
        font-size: toRem(14px);
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        cursor: pointer;

        @media screen and (max-width: 1000px) {
          font-size: toRem(12px);
        }
      }
    }
  }

  .location-layout {
    margin-left: 10px;
    margin-top: 10px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    border: 1px solid var(--greyscale_88);
    background-color: var(--brand_white);
    padding-bottom: 20px;
    border-radius: 8px;

    .section-location {
      margin-top: 20px;
      margin-left: 20px;
      background-color: var(--brand_white);
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: flex-start;

      .label-name {
        margin-left: 10px;
        color: var(--grayscale_16);
        font-size: toRem(24px);
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
      }

      .label-value {
        margin-left: 10px;
        color: var(--greyscale_48);
        font-size: toRem(16px);
        font-style: normal;
        font-weight: 600;
        line-height: 32px;
      }
    }
  }

  .accounts-details {
    margin-left: 10px;
    width: 33%;
    background-color: var(--brand_white);
    height: 100%;
    border-radius: 12px;

    @media screen and (max-width: 1000px) {
      width: 98%;
      margin-right: 0px;
    }

    .header-layout {
      padding-top: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-left: 25px;
      margin-right: 25px;

      .header-label {
        color: var(--brand_black);
        font-size: toRem(20px);
        font-style: normal;
        font-weight: 700;
        line-height: 28px;

        @media screen and (max-width: 1000px) {
          font-size: toRem(16px);
        }
      }

      .manage-label {
        color: var(--primary_40);
        font-size: toRem(14px);
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        cursor: pointer;

        @media screen and (max-width: 1000px) {
          font-size: toRem(12px);
        }
      }
    }

    .account-status-layout {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-top: 10px;

      .block-one-status {
        width: 46%;
        height: 116px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        border: 1px solid var(--greyscale_88);
        border-radius: 8px;

        .label-name {
          color: var(--grayscale_16);
          font-size: toRem(24px);
          font-style: normal;
          font-weight: 600;
          line-height: 32px;

          &.highlight-bg {
            background: var(--warning_96);
            padding-left: 15px;
            padding-right: 15px;
            padding-top: 2px;
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            color: var(--warning_24);
            border-radius: 8px;
          }

          @media screen and (max-width: 1199px) {
            font-size: toRem(16px);
          }
        }

        .label-value {
          color: var(--greyscale_48);
          font-size: toRem(14px);
          font-style: normal;
          font-weight: 400;
          line-height: 20px;

          &.highlight-sec {
            margin-top: 5px;
          }

          @media screen and (max-width: 1199px) {
            font-size: toRem(10px);
          }
        }
      }

      // .block-two-status {
      //   display: flex;
      //   flex-direction: column;
      //   justify-content: center;
      //   align-items: center;
      //   border: 1px solid var(--greyscale_88);
      // }
    }
  }
}

.role-and-partner-section {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: auto;

  @media screen and (max-width: 1000px) {
    flex-direction: column;
    justify-content: flex-start;
  }

  .roles-details {
    width: 33%;
    background-color: var(--brand_white);
    height: 100%;
    border-radius: 12px;
    // border: 1px solid var(--greyscale_88);

    @media screen and (max-width: 1000px) {
      width: 98%;
      margin-right: 0px;
    }

    .header-layout {
      padding-top: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-left: 25px;
      margin-right: 25px;

      .header-label {
        color: var(--brand_black);
        font-size: toRem(20px);
        font-style: normal;
        font-weight: 700;
        line-height: 28px;

        @media screen and (max-width: 1000px) {
          font-size: toRem(16px);
        }
      }

      .manage-label {
        color: var(--primary_40);
        font-size: toRem(14px);
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        cursor: pointer;

        @media screen and (max-width: 1000px) {
          font-size: toRem(12px);
        }
      }
    }

    .section-roles-colums {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: left;
      border: 1px solid var(--greyscale_88);
      background-color: var(--brand_white);
      padding: 9.5px;
      border-radius: 8px;
      margin-top: 10px;
      margin-right: 10px;

      .section-header-roles {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: left;

        &.margin-section {
          padding-top: 15px;
        }

        .section-roles {
          width: 50%;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: flex-start;

          .label-name {
            width: 12%;
            text-align: center;
            margin-left: 5px;
            color: var(--grayscale_16);
            font-size: toRem(24px);
            font-style: normal;
            font-weight: 600;
            line-height: 32px;

            &.highlight-bg {
              background: var(--warning_96);
              padding-left: 5px;
              padding-right: 5px;
              display: flex;
              flex-direction: row;
              align-items: center;
              justify-content: center;
              color: var(--warning_24);
              border-radius: 8px;
            }
          }

          .label-value {
            width: 88%;
            text-align: left;
            margin-left: 10px;
            color: var(--greyscale_48);
            font-size: toRem(14px);
            font-style: normal;
            font-weight: 400;
            line-height: 32px;
          }
        }
      }
    }
  }

  .partner-flow-section {
    width: 67%;

    @media screen and (max-width: 1000px) {
      width: 98%;
      margin-right: 0px;
    }

    .header-layout {
      padding-top: 10px;
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-left: 35px;
      margin-right: 25px;

      .header-label {
        color: var(--brand_black);
        font-size: toRem(20px);
        font-style: normal;
        font-weight: 700;
        line-height: 28px;

        @media screen and (max-width: 1000px) {
          font-size: toRem(16px);
        }
      }

      .manage-label {
        color: var(--primary_40);
        font-size: toRem(14px);
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        cursor: pointer;

        @media screen and (max-width: 1000px) {
          font-size: toRem(12px);
        }
      }
    }

    .main-section-partner {
      background: var(--grayscale_96);
      border-radius: 10px;
      margin-top: 10px;
      margin-left: 18px;
      height: 242px;
      justify-content: flex-start;
      display: flex;
      flex-direction: column;
      overflow: auto;

      &.no-patner {
        justify-content: center;
        display: flex;
        flex-direction: row;
        align-items: center;
      }

      .section-partner {
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        justify-content: space-between;
        margin-top: 10px;

        &.no-invite-section {
          margin-top: 20px;
        }

        .section-left-patner-details {
          width: 50%;
          margin-left: 20px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          .label-name {
            color: var(--greyscale_56);
            font-size: toRem(12px);
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            text-align: left;

            &.other-label {
              margin-top: 10px;
            }
          }

          .label-value {
            color: var(--brand_black);
            font-size: toRem(14px);
            font-style: normal;
            font-weight: 500;
            line-height: 20px;
            text-align: left;
          }
        }

        .section-technicians {
          width: 50%;
          margin-left: 20px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          justify-content: flex-start;

          .technicians-title-area {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            .label-name {
              color: var(--greyscale_56);
              font-size: toRem(12px);
              font-style: normal;
              font-weight: 500;
              line-height: 16px;
              margin-left: 5px;
              text-align: left;

              &.other-label {
                margin-top: 10px;
              }
            }

            .view-all-button {
              margin-right: 24px;

              color: var(--primary_40);
              font-size: toRem(16px);
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
              cursor: pointer;
            }
          }

          .no-technician {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            margin-top: 8px;

            .bg-no-tech {
              border-radius: 100%;
              height: 32px;
              width: 32px;
              align-items: center;
              display: flex;
              flex-direction: column;
              justify-content: flex-end;
              background: var(--greyscale_88);

              .img-no-tech {
                height: 33px;
                width: 33px;
              }
            }
             .label-no-tech {
              margin-left: 8px;
              color: var(--greyscale_56);
              font-size: 14px;
              font-style: normal;
              font-weight: 500;
              line-height: 20px;
            }
          }

          .technicians-list {
            width: 100%;
            height: auto;
          }
        }
      }
    }
  }
}

// overwriting modal styles of device.scss
.modal {
  &-body:has(.technician-modal-area) {
    &::-webkit-scrollbar {
      display: block !important;
      width: 6px !important;
    }
    margin-bottom: 16px;
  }
  &-content:has(.technician-modal-area) {
    padding: 0 !important;
    width: 100%;
    .modal-header {
      padding: 32px;
    }
  }
}

.technician-view-all-modal {
  @include general-scrollbar;
}

.technician-modal-area {
  max-height: calc(100% - 88px);
  overflow-y: auto;

  .technician-list {
    padding: 0 32px;
    .technician-list-item {
      padding-left: 20px;
      width: 100%;
      height: 68px;
      margin-bottom: 12px;
      border-radius: 12px;
      background: var(--light_grey);
      list-style: none;
      display: flex;
      flex-direction: row;
      align-items: center;

      .technician-img-area {
        img {
          border-radius: 50%;
          width: 32px;
          height: 32px;
        }
      }

      .technician-name {
        margin-left: 8px;
        color: var(primary_40);
        font-size: toRem(14px);
        font-style: normal;
        font-weight: 500;
        line-height: 20px;
        text-align: left;
      }
    }
  }
}

.pending-invite {
  margin-top: 10px;
  background-color: var(--warning_96);
  padding: 10px;
  border-radius: 8px;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 20px;
  margin-right: 20px;

  .label-pending {
    margin-left: 10px;
    font-size: toRem(14px);
    font-weight: 400;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: var(--warning_24);
  }
}

.avatars-list-title {
  color: var(--brand_black);
  font-size: toRem(14px);
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-left: 10px;
  text-align: left;
}

.avatars-list {
  margin-bottom: 2px;

  .container {
    padding-left: 1px;
  }

  .site-avatars {
    margin-right: 1px;
    border: 1px solid var(--greyscale_80);
    border-radius: 100%;
    margin-top: 5px;
  }
}
.new-c-wdt-l{
  width: 100%;
  margin-left: 5px;
}
.info-notification{
  display: flex;
  padding: 12px;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--primary_96);
  color: var(--primary_24);
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 20px; /* 125% */
  margin: 44px 0px;
}
.admin-container {
  display: flex;
  width: 1110px;
  padding: 24px;
  flex-direction: column;
  align-items: flex-start;
  gap: 24px;
  border-radius: 12px;
  border: 1px solid var(--greyscale_88);
  background: var(--brand_white);
  width: 100%;

  .admin-title {
    color: var(--brand_black);
    font-size: 20px;
    font-style: normal;
    font-weight: 700;
    line-height: 28px; /* 140% */
  }

  .admin-details {
    display: flex;
    align-items: flex-start;
    gap: 8px;
    align-self: stretch;
    flex-direction: row;
  }
  .info-cintainer {
    display: flex;
    padding: 6px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 4px;
  }
  .name {
    color: var(--greyscale_08);
    font-size: 16px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px; /* 125% */
  }
  .info{
    color: var(--grayscale_24);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16px; /* 133.333% */
  }
  .seperator {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
    border-radius: 12px;
    border: 1px solid var(--greyscale_88);
    background: var(--brand_white);
  }
  .initials {
    width: 32px;
    height: 32px;
    flex-shrink: 0;
    background: var(--greyscale-greyscale-88, #DCDFE5);
    color: var(--greyscale-greyscale-40, #566376);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 20px; /* 142.857% */
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 50%;
  }
}
.installer-list-container {
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
  display: flex;
  .installer-list {
    margin-right: 45px;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    display: flex;
  }
  .width-11 {
    max-width: 14%;
  }
  .width-20 {
    max-width: 20%;
  }
}

.OrgId {
  border-radius: 4px;
  padding: 2px 2px 2px 2px;
  gap: 2px;
  height: auto;
  width: auto;
  font-size: 12px;
  color: var(--button-color-1);
  font-weight: 400;
  line-height: 15.6px;
  &:hover {
    background-color: #DFE1E2;
    border-radius: 4px;
  }
  &:active,
  &:focus {
    background-color: #F3732159;
    border-radius: 4px;
  }
}
.btnCOpy {
  width: 20px;
  height: 20px;
  margin-bottom: 2px;
}
