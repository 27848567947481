@import '../../assets/css/utilities';

.ctn-box {
  padding: 0;
  margin: 0;

  .header-view {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .titlename-header {
      font-weight: 700;
      font-size: toRem(20px);
      line-height: 28px;
      color: var(--brand_black);
      margin-bottom: 4px;
    }
  }

  .device-claimed-status {
    margin-top: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    .claimed-view {
      background: var(--success_64);
      border-radius: 50%;
      width: 96px;
      height: 96px;
      display: flex;
      justify-content: center;
      align-items: center;

      .image-tick {
        height: 21px;
        width: 32px;
      }
    }
  }
}

.innner,
.innner-fluid,
.innner-xxl,
.innner-xl,
.innner-lg,
.innner-md,
.innner-sm {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0);
  margin-right: auto;
  margin-left: auto;
}

.titlename {
  font-weight: 600;
  font-size: toRem(20px);
  line-height: 28px;
  color: var(--brand_black);
  margin-bottom: 4px;
}

.header-title-steps {
  font-style: normal;
  font-weight: 700;
  font-size: 1.25rem;
  line-height: 28px;
  color: var(--brand_black);
}

.subtext {
  font-style: normal;
  font-weight: 400;
  font-size: toRem(14px);
  line-height: 20px;
  color: var(--greyscale_56);
  margin-top: 2px;
}

.subtext2 {
  font-weight: 500;
  font-size: toRem(14px);
  line-height: 20px;
  color: var(--greyscale_08);
  margin-bottom: 8px;
}

.subtext3 {
  font-weight: 500;
  font-size: toRem(12px);
  line-height: 16px;
  color: var(--greyscale_56);

  &.text-alignemt {
    text-align: center;
  }
}

.steptext {
  font-weight: 500;
  font-size: toRem(12px);
  line-height: 20px;
  color: var(--greyscale_56);
  margin-bottom: 16px;
}

.inputborder {
  border: 1.5px solid var(--greyscale_88);
  border-radius: 12px;
}

.subttext-block {
  padding: 16px 16px;
}

.adddevice-btn {
  font-weight: 600;
  font-size: toRem(14px);
  line-height: 20px;
  color: var(--greyscale_08);
  margin-top: 10px;
}

// .btn2 {
//   margin-left: 8px;
// }

.radio-wrapper {
  border-radius: 12px;
  background: var(--grayscale_96);
  margin-bottom: 16px;

  .radio-options-wrapper {
    padding: 10px 0;
  }
}

.radiotitle {
  display: flex;
}

.stepIcon>img {
  margin-left: 0;
  margin-top: 0;
}

.radiobutton {
  padding: 10px 16px;
}

.makingBig>input {
  height: 20px;
  width: 20px;
  margin-left: 10px;
}

.qr-size {
  width: 390px;
}

.qr-block-1 {
  margin-bottom: 8px;
  position: relative;
}

.qr {
  &-box {
    position: absolute;
    z-index: 1;
    top: -44px;
    height: 234px;
    overflow: hidden;
    justify-content: center;
    margin-top: 0;
    margin: auto;
    align-items: center;
  }

  &-block {
    background: var(--grayscale_96);
    border-radius: 12px;
    margin-bottom: 16px;
    width: 100%;
    height: 136px;
  }

  &-image {
    display: flex;
    justify-content: center;
    width: 200px;
    height: 136px;
    margin: auto;
    align-items: center;
    object-fit: contain;
  }
}

.modal {
  &-width {
    max-width: 542px !important;
  }

  &-form {
    .form-control {
      padding: 12px 16px;
      margin-bottom: 8px;
      height: 44px;

      &::placeholder {
        font-size: toRem(14px) !important;
      }
    }

    .input-with-icons {
      .icon-before-input {
        position: absolute;
        opacity: 1;
        height: 44px;
        width: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin: auto;
      }
    }

    .accordion {
      .accordion-item {
        border: 0;

        .accordion-header {
          .accordion-button {
            background-color: inherit;
            box-shadow: none;

            &:not(.collapsed)::after {
              background-image: var(--bs-accordion-btn-icon);
            }
          }
        }

        .accordion-body {
          padding: 8px 16px 5px;

          .radiotitle {
            margin-bottom: 16px;
          }
        }
      }
    }

    .device-name {
      position: relative;

      span.placeholder-wrapper::after {
        content: ' *';
        color: red;
        position: absolute;
        top: 6px;
        right: 5px;
      }

      .device-name-length {
        color: var(--greyscale_56);
        font-size: toRem(12px);
        font-weight: 500;
        line-height: 16px;
        position: absolute;
        right: 20px;
        top: 50px;
      }
    }

    .tabs-container {
      width: 100%;
      border-radius: 12px;
      padding: 4px;
      background-color: var(--grayscale_96);
      border: none;

      .nav-item {
        width: 50%;
        // height: 40px;

        button {
          width: 100%;
          border-radius: 12px;
          padding: 8px 16px;
          border: none;
          color: var(--greyscale_56);

          &.active {
            background: var(--primary_40);
            color: var(--brand_white);
          }
        }
      }
    }
  }

  &-body {
    max-height: 65vh;
    overflow-y: auto;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      display: none;
    }
  }
}

select:disabled {
  opacity: 1;
  color: var(--greyscale_56);
}

.form {
  &-select {
    padding: 12px 16px;
    height: 44px;
    border: 1.5px solid var(--greyscale_88);
    border-radius: 12px;
    font-weight: 500;
    font-size: toRem(14px);
    line-height: 20px;
    margin-bottom: 8px;
    color: var(--greyscale_08);

    &.is-invalid {
      background-color: var(--error_96);
      border: 1.5px solid var(--error_64);
      margin-bottom: 0;
    }

    &.border-line {
      border-radius: 12px;
    }
  }
}

.was-validated {
  .form {
    &-select {
      &:invalid {
        background-color: var(--error_96);
        border: 1.5px solid var(--error_64);
        margin-bottom: 0;
      }
    }
  }
}

// table
.device {
  &-btn {
    &-list {
      font-weight: 600;
      font-size: toRem(14px);
      line-height: 20px;
    }

    &-space {
      margin-left: 14px;
    }
  }

  &-table {
    thead {
      box-sizing: border-box;

      th {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
        max-width: 100px;
      }
    }

    tbody {
      tr {
        box-sizing: border-box;

        td {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 90px;

          &:first-child {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            max-width: 100px;
          }

          &:last-child {
            width: 30px !important;
          }
        }
      }
    }
  }

  &-list {
    &-dropdown {
      .dropdown-menu {
        border: 0;

        .dropdown-item {
          color: var(--greyscale_40);
          padding: 6px 16px;
          background: none;

          &:hover {
            color: var(--brand_black);
          }
        }

        &.show {
          background: var(--brand_white);
          box-shadow: 0px 10px 15px -3px rgba(var(--greyscale-custom1), 0.1),
            0px 4px 6px -2px rgba(var(--greyscale-custom1), 0.05);
          border-radius: 12px;
        }
      }

      .dropdown-toggle {
        &.btn {
          background: transparent;
          border: 0;

          &::before {
            display: none;
          }
        }
      }
    }
  }
}

a.primary-link {
  padding: 10px 16px;
  font-size: toRem(14px);
  line-height: 20px;
  color: var(--primary_40);
  border: 1.5px solid var(--primary_40);
  text-decoration: none;
  border-radius: 12px;
  cursor: pointer;
  display: inline-block;

  &.disabled {
    color: var(--greyscale_72);
    border: 1.5px solid var(--grayscale_96);
  }

  &:hover {
    background-color: var(--primary_96);
  }
}

.end-align {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.confirmation {
  &-modal {
    .modal-header {
      justify-content: center;
      position: relative;
      padding: 0;

      .site-modal-close {
        cursor: pointer;
        color: var(--dark_gray);
        padding: 1.5px;
        font-weight: 600;
        stroke-width: 1;
        position: absolute;
        right: 0;
      }
      .site-modal-close:hover {
        color: var(--button-color-1); /* Hover color */
      }
      .site-modal-close:active {
        color: var(--dark_orange); /* Color on press */
      }
    }

    .confirmation-modal-content {
      text-align: center;

      .icons {
        margin: 25px 0;
      }

      .confirmation-content {
        color: var(--greyscale_56);
        font-size: toRem(16px);
        line-height: 24px;
        font-weight: 400;
      }
    }
  }
}

.remote-view-wrapper {
  width: 100%;
}

.mac-address-formate {
  color: var(--greyscale_56);
  font-weight: 500;
  font-size: toRem(12px);
  line-height: 16px;
  margin-left: 2px;
  margin-bottom: 8px;
  font-style: normal;
  font-family: 'Noto Sans';
}

.mac-address-formate svg {
  margin-left: 1px;
  width: 24px;
  height: 16px;
  border: 1.5px;
  gap: 17px;
}

.text-box {
  border-radius: 5px;
  --b: 3px;
  /* thickness of the border */
  --c: var(--greyscale_80);
  /* color of the border */
  --w: 20px;
  /* width of border */
  border: var(--b) solid rgba(var(--greyscale-custom1), 0);
  /* space for the border */
  --_g: rgba(var(--greyscale-custom1), 0) 90deg, var(--c) 0;
  --_p: var(--w) var(--w) border-box no-repeat;
  background: conic-gradient(from 90deg at top var(--b) left var(--b),
      var(--_g)) 0 0 / var(--_p),
    conic-gradient(from 180deg at top var(--b) right var(--b), var(--_g)) 100% 0 / var(--_p),
    conic-gradient(from 0deg at bottom var(--b) left var(--b), var(--_g)) 0 100% / var(--_p),
    conic-gradient(from -90deg at bottom var(--b) right var(--b), var(--_g)) 100% 100% / var(--_p);
  width: 98%;
  height: 200px;
  box-sizing: border-box;
  margin: 5px 5px -2px 5px;
  display: inline-flex;
  font-size: toRem(30px);
  justify-content: center;
  align-items: center;
  text-align: center;
}

.qr-holder {
  background-color: var(--grayscale_96);
  border-radius: 5px;
}

.qr-scan-wrapper {
  min-height: 294px;
}

.device_list {
  padding: 20px 0;
  min-height: 120px;
}

.camera-details-container {
  display: flex;
  background: var(--grayscale_96);
  padding: 1.5rem;
  border-radius: 0.75rem;

  & .qr {
    &-block {
      width: auto;
      height: auto;
      background: none;
      border: none;
      margin: 0px;
      display: flex;
    }

    &-image {
      width: 140px;
    }
  }

  & .camera-details {
    margin-left: 24px;
  }
}

.camera-details-container-mobile {
  display: flex;
  background: var(--grayscale_96);
  padding: 1.5rem;
  border-radius: 0.75rem;

  & .qr {
    &-block {
      width: auto;
      height: auto;
      background: none;
      border: none;
      margin: 0px;
      display: flex;
    }

    &-image {
      width: 140px;
    }
  }

  & .camera-details {
    margin-left: 24px;
  }

  & .camera-details-mobile {
    margin-left: 100px;
  }
}

.ctn-box.innner {
  padding-right: 0px;
}

.step2-location {
  .accordion-button::before {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    content: '';
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
    margin-right: 10px;
    margin-top: -4px;
  }

  .accordion-button:not(.collapsed)::before {
    background-image: var(--bs-accordion-btn-active-icon);
    transform: var(--bs-accordion-btn-icon-transform);
  }

  .accordion-button::after {
    background-image: none;
  }

  .accordion-button:not(.collapsed)::after {
    background-image: none !important;
  }

  .radio-wrapper {
    .radiotitle {
      .subtext2 {
        font-size: toRem(16px);
      }
    }

    .accordion-body {
      .radiotitle {
        .subtext2 {
          margin-left: 32px;
        }
      }
    }
  }
}

.nvr-mode.radio-wrapper {
  padding: 20px 20px 20px 29px;

  .radiotitle {
    line-height: 20px;
    padding: 6px 0;
    align-items: center;
    margin-top: 4px;

    &:first-child {
      margin-top: 0px;
    }

    .makingBig {
      display: flex;
      align-items: center;

      input {
        margin-left: 0px;
      }
    }
  }
}

.discovered-devices {
  .modal-body {
    min-height: 100%;

    &.errorMsg {
      min-height: 728px;
    }
  }

  .device-container-mobile {
    min-height: 100%;

    .warning-container-mobile {
      display: flex;
      justify-content: space-between;
      padding: 12px;
      margin: 12px 0;
      align-items: center;
      border-radius: 12px;
      background: var(--warning_96);

      .warning-message-mobile {
        display: flex;
        color: var(--warning_24);
        font-size: toRem(14px);
        font-weight: 400;
        line-height: 20px;

        svg {
          margin-right: 8px;
          width: 30px;
        }
      }
    }

    .filter-container {
      border: none;

      .filter-tabs {
        border: none;

        .nav-item {
          &:first-child {
            padding-left: 0px;
          }

          padding: 0px 4px;

          button {
            background: var(--grayscale_96);
            border-radius: 8px;
            font-size: 12px;
            font-weight: 600;
            line-height: 16px;
            color: var(--grayscale_32);

            &.active {
              background: var(--primary_40);
              color: var(--brand_white);
            }
          }
        }
      }
    }

    .loading-screen {
      min-height: 580px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .devices-section-mobile {
      display: contents;
      justify-content: space-between;

      .devices-list {
        overflow-y: scroll;
        height: 434px;
        padding-right: 4px;

        &::-webkit-scrollbar-thumb {
          background: var(--greyscale_64);
        }

        .device-item {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 12px;
          border-radius: 8px;
          border: 1px solid var(--greyscale_88);
          margin-top: 8px;
          cursor: pointer;

          &.active-device {
            background: var(--primary_96);
            border: 1px solid var(--primary_88);
          }

          &:first-child {
            margin-top: 0;
          }

          .item-details {
            .item-name {
              margin-top: 0px;
              font-weight: 600;
              color: var(--brand_black);
            }

            .seconday-text {
              font-size: toRem(12px);
              font-weight: 400;
              line-height: 16px;
            }
          }

          .item-status {
            font-size: toRem(12px);
            font-weight: 600;
            line-height: 16px;
            color: var(--primary_40);
            cursor: pointer;

            &.disabled {
              color: var(--greyscale_80);
            }

            &.already-assigned-status {
              white-space: nowrap;
              color: var(--brand_black);
            }
          }
        }
      }

      .device-details {
        width: 380px;
        border-radius: 12px;
        background: var(--grayscale_96);
        overflow-y: scroll;
        position: relative;

        .loader-wrapper {
          .lds-ring {
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }

        .device-details-container {
          padding: 24px;
          
          &.disable-form {
            opacity: 0.6;
            pointer-events: none;
          }
        }

        .header-container {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .status-section {
            color: var(--success_48);
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            display: flex;

            img {
              margin-right: 4px;
            }
          }

          .pending-status-section {
            color: var(--warning_48);
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            display: flex;

            svg {
              margin-right: 4px;

              path {
                stroke: var(--warning_48);
              }
            }
          }
          .error-status-section {
            color: var(--error_48);
            font-size: 14px;
            font-weight: 500;
            line-height: 20px;
            display: flex;
            text-transform: capitalize;

            svg {
              margin-right: 4px;
              width: 17px;

              path {
                stroke: var(--error_48);
              }
            }
          }
        }

        .item-name {
          font-weight: 600;
          color: var(--brand_black);
          font-size: toRem(16px);
          margin-top: 0px;
        }

        .seconday-text {
          font-size: toRem(12px);
          font-weight: 400;
          line-height: 16px;
        }

        .modal-form {
          .device-image-wrapper {
            margin: 12px 0;

            img {
              max-width: 100%;
              max-height: 100%;
            }
          }

          .fieldTitle {
            font-size: toRem(14px);
            font-weight: 600;
            line-height: 20px;
          }

          .username-field {
            margin-top: 0.75rem;
          }

          .apply-all-creds {
            display: flex;
            align-items: center;
            margin-top: 0.25rem;

            input {
              margin-top: 0px;
            }

            label {
              font-size: toRem(12px);
              font-weight: 400;
              line-height: 16px;
              color: var(--greyscale_56);
              margin-left: 8px;
            }
          }

          .radio-wrapper {
            .radiotitle {
              justify-content: space-between;
              align-items: center;
              padding: 6px 0;

              .area-name {
                font-size: toRem(16px);
                font-weight: 500;
                line-height: 20px;
              }

              .makingBig {
                display: flex;
              }
            }
          }
        }
      }
    }

    .header-container {
      width: 100%;
      padding: 16px 24px;
      border-radius: 12px;
      display: flex;
      justify-content: space-between;
      background: var(--grayscale_96);

      .left-section {
        display: flex;
        align-items: center;

        .device-icon {
          max-width: 32px;
          max-height: 100%;
          margin-right: 12px;
        }

        .device-details {
          line-height: 20px;
          font-size: toRem(14px);

          .device-name {
            font-weight: 600;
            // width: 300px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }
          .deviceName-Break{
            max-width: 120px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            border-left: 0;
            border-right: 0;
          }

          .device-added-status {
            font-weight: 400;
            color: var(--greyscale_48);
          }
        }
      }

      .right-section {
        display: flex;

        .scan-network-button {
          display: flex;
          align-items: center;
          margin-right: 22px;
          cursor: pointer;
          &.disabled {
            cursor: initial;
          }

          .scan-button {
            font-weight: 600;
          }

          .refresh-icon {
            path {
              stroke: var(--brand_primary);
            }
          }

          .disabled-icon {
            path {
              stroke: var(--greyscale_72);
            }
          }
        }
      }
    }

    .footer-container {
      width: 100%;
      padding: 16px 24px;
      border-radius: 12px;
      // display: flex;
      justify-content: space-between;
      background: var(--grayscale_96);

      .left-section {
        display: flex;
        align-items: center;

        .device-icon {
          max-width: 32px;
          max-height: 100%;
          margin-right: 12px;
        }

        .device-details {
          line-height: 20px;
          font-size: toRem(14px);

          .device-name {
            font-weight: 600;
            // width: 300px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
          }

          .device-added-status {
            font-weight: 400;
            color: var(--greyscale_48);
          }
        }
      }

      .right-section {
        display: flex;

        .scan-network-button {
          display: flex;
          align-items: center;
          margin-right: 22px;
          cursor: pointer;
          &.disabled {
            cursor: initial;
          }

          .scan-button {
            font-weight: 600;
          }

          .refresh-icon {
            path {
              stroke: var(--brand_primary);
            }
          }

          .disabled-icon {
            path {
              stroke: var(--greyscale_72);
            }
          }
        }
      }
    }

  }

  .modal-footer {
    border: none;
    padding: 0px;

    &>* {
      margin: 0px;
    }
  }
}

.device-status-selector {
  &-menu {
    &.custom-class {
      margin-left: 0 !important;
      width: 100% !important;
    }
  }

  &-toggle {
    &.custom-class {
      width: 100%;
    }
  }

  &.dropdown {
    border: 1px solid var(--greyscale_88);
    border-radius: 10px;
  }
}

.device-status-selected-wrapper {
  float: left;
}

.dropdownContainer {
  width: 505px;
}

.dropdownContainerRow {
  &.row {
    width: 510px;
  }
}

.custom-background {
  background-color: var(--brand_white) !important;
}

.iconInDropDown {
  width: 27px;
}

.errorStyle {
  margin-left: 18px;
}

.dropDownName {
  color: var(--greyscale_08);
}

.disabled-dropdown-item {
  background-color: var(--greyscale_88) !important;
}

#checkBoxDropDown {
  accent-color: var(--dark_orange);
}

#dropdown-basic-2 {
  width: 326px;
}

.device-status-selector-menu {
  &.custom-class1 {
    &.dropdown-menu {
      &.show {
        width: 326px;
        margin-left: 0px;
        height: 169px;
      }
    }
  }
}

.addDeviceBtnStyle {
  outline: none !important;
}

@media screen and (max-width: 767px) {
  .text-end .addDeviceMobileButton {
    background-color: rgb(220, 223, 229);
    width: 100% !important;

  }
  .accordion-item .device-btn-list {
    font-size: 16px !important;
    border-radius: 8px !important;
    padding: 7px 12px !important;
  }
}

.discovered-devices .device-container-mobile .footer-container {
  margin-top: auto;
}

button.scan-button.text-right {
  min-height: 33px;
}

.device-details-mobile {
  .item-name {
    font-weight: 600;
    color: var(--brand_black);
    font-size: toRem(16px);
    margin-top: 0px;
  }

  .status-section {
    color: var(--success_48);
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    img {
      margin-right: 4px;
    }
  }

  .seconday-text {
    font-size: toRem(12px);
    font-weight: 400;
    line-height: 16px;
  }

  .fieldTitle {
    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    font-family: 'Noto Sans';
  }

  .apply-all-creds {
    display: flex;
    align-items: center;
    margin-top: 0.25rem;

    input {
      margin-top: 0px;
    }

    label {
      font-size: toRem(12px);
      font-weight: 400;
      line-height: 16px;
      color: var(--greyscale_56);
      margin-left: 8px;
    }
  }
 
  .header-container {
    justify-content: space-between;
    align-items: center;

    .status-section {
      color: var(--success_48);
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
   
      img {
        margin-right: 4px;
      }
    }

    .pending-status-section {
      color: var(--warning_48);
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      display: flex;

      svg {
        margin-right: 4px;

        path {
          stroke: var(--warning_48);
        }
      }
    }
    .error-status-section {
      color: var(--error_48);
      font-size: 14px;
      font-weight: 500;
      line-height: 20px;
      display: flex;
      text-transform: capitalize;

      svg {
        margin-right: 4px;
        width: 17px;

        path {
          stroke: var(--error_48);
        }
      }
    }
  }
}

.radio-wrapper {
  .radiotitle {
    justify-content: space-between;
    align-items: center;
    padding: 15px;

    .area-name {
      font-size: toRem(16px);
      font-weight: 500;
      line-height: 20px;
    }

    .makingBig {
      display: flex;
    }
  }
}

.username-field {
  margin-top: 12px;
}

.device-image-wrapper img {
  width: 100%;
  margin-top: 12px;
}
button.scan-button.text-wrap {
  font-size: 12px;
}
.refresh-icon {
  path {
    stroke: var(--brand_primary);
  }
}