@import '../../assets/css/utilities';

.partner-customers-list-wrap {
  .partner-customers-list-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px;
    background: var(--grayscale_96);
    border-radius: 12px;
    margin-bottom: 8px;

    &:last-child {
      margin-bottom: 0;
    }

    .partner-customers-list-item-label {
      font-style: normal;
      font-weight: 500;
      font-size: toRem(14px);
      line-height: 20px;
    }

    .partner-customers-list-item-input {
      height: 20px;
      width: 20px;
    }
  }
}

.reset-password-partner-user-modal,
.send-invitation-partner-user-modal {
  .modal-header {
    .modal-title {
      width: 100%;
      text-align: center;
    }
  }
}

.edit-partner-user-modal {
  .modal-header {
    padding-bottom: 0;
  }
  .status-info,
  .btn {
    font-size: 14px;
    line-height: 20px;
    font-weight: 500;
  }
}

.spinner-wrapper {
  margin-left: 5px;
}

.icon-succuess-color {
  fill: var(--success_64);
}
// section.assign-customer-scroll {
//     max-height: inherit;
//     overflow-x: hidden;
//     overflow-y: auto;    
// }
