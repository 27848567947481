.paginated-devices {
  text-align: left;
  display: flex;
  flex-direction: column;
  font-family: Noto Sans;
  width: 100%;
  height: calc(100vh - 130px);

  .devices-top-area {
    @media screen and (max-width: 1442px) {
      padding: 32px 174px 16px;
    }

    @media screen and (min-width: 1443px) {
      padding: 32px 180px 16px;
    }
  }

  .devices-bottom-area {
    @media screen and (max-width: 1442px) {
      padding-left: 174px;
    }

    @media screen and (min-width: 1443px) {
      padding-left: 180px;
    }

    flex: 1;
    display: flex;
    overflow: hidden;

    &-contents {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex: 1;
      overflow: auto;
      scrollbar-gutter: stable;

      .pagination-container {
        min-width: 1080px;
      }

      @media screen and (max-width: 1442px) {
        padding: 0 168px 32px 0;
      }

      @media screen and (min-width: 1443px) {
        padding: 0 174px 32px 0;
      }
    }

    .table-responsive {
      margin-top: 0;
      overflow: initial;
      width: 100%;
    }

    thead {
      position: sticky;
      top: 0;
      z-index: 10;
      box-shadow: 0px -1px 0px white;
    }

    tr td {
      overflow: initial !important;
    }

    .grip-handle {
      z-index: 11;
    }
  }

  .devices-header {
    display: flex;
    flex-direction: column;
    gap: 4px;
    width: 100%;
    text-align: left;

    .devices-title {
      font-size: 24px;
      font-weight: 600;
      line-height: 32.69px;
      color: var(--greyscale_00);
      .back-mode-title {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }
    }
  }

  .devices-filters {
    display: flex;
    height: max-content;
    justify-content: space-between;
    margin-top: 7px;
    flex-wrap: wrap;
    gap: 16px;

    .reload-filter-edit {
      width: max-content;
      min-width: 100px;
      height: 32px;
      padding: 7px 16px 8px 16px;
      gap: 2px;
      border-radius: 4px;
      border: 1px solid var(--button-tertiary-outline-default);
      align-items: center;
      display: flex;
      justify-content: center;
      color: rgba(var(--custom_color_17));
      text-align: center;
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      white-space: nowrap;

      &:hover {
        background-color: rgba(var(--custom_color_18));
      }

      &:active {
        border: 1px solid rgba(var(--custom_color_19));
        background: rgba(var(--custom_color_26));
      }
    }
    .disabled {
      border: 1px solid rgba(var(--custom_color_19));
      color: rgba(var(--custom_color_20));
      pointer-events: none;
    }
    .remove-device {
      width: 120px !important;
    }

    .left-side {
      display: flex;
      height: max-content;
      gap: 8px;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      text-align: left;
      flex-wrap: wrap;

      .count-container {
        font-weight: 400;
        display: flex;
        gap: 4px;

        .display-bold {
          font-weight: 700;
        }

        width: max-content;
      }

      .add-device-selector {
        width: 120px;

        &-toggle {
          &.dropdown-toggle {
            display: flex;
            padding: 0px 12px;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            gap: 8px;
            align-self: stretch;
            width: 100%;
            border: 1px solid var(--custom_color_16) !important;
            border-radius: 4px !important;
            background: initial;
            color: var(--custom_color_21);
            font-family: 'Noto Sans';
            font-size: 14px !important;
            font-weight: 400;
            line-height: 20px !important;
            height: 32px;

            &:active,
            &:focus,
            &:focus-visible,
            &:focus-within,
            &:hover {
              border: 1px solid var(--custom_color_16) !important;
              border-radius: 4px !important;
              background: initial !important;
              color: var(--custom_color_19) !important;
            }

            &.btn-outline-secondary {
              &.show {
                border: 1px solid var(--custom_color_16) !important;
                border-radius: 4px !important;
                background: initial;
                color: var(--custom_color_19);
              }
            }

            &::after {
              display: none !important;
            }
          }
        }

        &-menu {
          overflow-y: auto;
          width: 200px;
          max-height: 240px;
          padding: 4px;

          &.dropdown-menu {
            width: 100%;
            padding: 8px 0px;
            background: var(--brand_white);

            &.show {
              box-shadow: 0px 10px 15px -3px rgba(var(--greyscale-custom1), 0.1),
                0px 4px 6px -2px rgba(var(--greyscale-custom1), 0.05);
              border-radius: 4px;
            }
          }

          &-item {
            &.dropdown-item {
              width: 100%;
              display: flex;
              height: 32px;
              padding: 0px 8px;
              align-items: center;
              font-size: 14px;
              font-weight: 500;
              line-height: 20px;
              border: none;
              background: initial;
              color: var(--brand_black);
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;

              &.active {
                background: var(--hGreyscale_88);
                color: var(--brand_black);
                font-weight: 700;
              }

              &.disabled {
                opacity: 0.5;
              }

              &:first-child {
                border-top-left-radius: 0px;
                border-top-right-radius: 0px;
              }

              &:last-child {
                border-bottom-left-radius: 0px;
                border-bottom-right-radius: 0px;
              }
            }

            &.dropdown-item:hover {
              background: rgba(var(--custom_color_26));
              border-top-left-radius: 0px;
              border-top-right-radius: 0px;
              border-bottom-left-radius: 0px;
              border-bottom-right-radius: 0px;
            }
          }
        }

        .add-device-selector {
          &-wrapper {
            display: inline-flex;
            align-items: center;
            border-radius: 4px;
          }

          &-name {
            margin-left: 0px !important;
          }
        }
      }
      .searchable-selector {
        width: 320px;
        height: 31px;
        .drop-down-container{
          background: var(--brand_white)
        }
        .default-container {
          height: 315px;
          align-items: center;
          justify-content: center;
          display: flex;

          color: var(--Text-Normal-Secondary, rgba(0, 0, 0, 0.77));
          text-align: center;
          font-family: "Noto Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 16px;

          .no-locations-container{
            display: flex;
            width: 162px;
            flex-direction: column;
            align-items: center;
            gap: 4px;
          }
          .site-spinner {
            padding: 2.5px;
          }
        }
        .search-container {
          text-align: center;
          align-items: center;
          width: 100%;
          display: flex;
          flex-direction: column;
          border: none;
          background: inherit;
          padding: 10px;
          height: 40px;
          position: absolute;
          top: 35px;
          z-index: 9999;
          border-radius: 4px 4px 0px 0px;
          border: 1px solid var(--Border-Normal-Default, rgba(0, 0, 0, 0.08));

          & .search-icon {
            z-index: 9;
            margin: 0px 8px 0px 8px;
            position: absolute;
            left: 0;
          }

          & .search-input {
            padding: 0px 20px 0px 24px;
            color: var(--greyscale_00);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 20px;
            background: initial;
            width: 100%;
            display: flex;
            align-items: center;
            border: none;

            &:hover {
              background: initial !important;
              color: var(--greyscale_00) !important;
              border-color: var(--dark_orange);
            }

            &:focus {
              color: var(--greyscale_00);
              background-color: var(--bs-body-bg);
              border-color: var(--greyscale_buttons_2); // ToDo: Need to Fix
              outline: 0;
            }
          }

          & .search-close {
            position: absolute;
            cursor: pointer;
            right: 0;
            margin: 2px 10px 0px 0px;
          }
        }

        &-toggle {
          &.dropdown-toggle {
            display: flex;
            padding: 6px 8px;
            flex-direction: row;
            align-items: flex-start;
            justify-content: space-between;
            gap: 8px;
            align-self: stretch;
            width: 100%;
            border: 1px solid var(--Input-Outline-Default, rgba(0, 0, 0, 0.21));
            background: var(--Input-Foreground-Default, rgba(0, 0, 0, 0.00));
            border-radius: 4px !important;
            color: var(--custom_color_17);
            font-family: "Noto Sans";
            font-size: 14px !important;
            font-weight: 400;
            line-height: 20px !important;
            max-width: 320px;
            min-width: 320px;
            height: 32px;

            &:hover {
              border-radius: 4px;
              border: 1px solid var(--Input-Outline-Hover, rgba(0, 0, 0, 0.29)) !important;
              background: var(--Input-Foreground-Hover, rgba(0, 0, 0, 0.04)) !important;
              color: var(--custom_color_17) !important;
            }

            &:active {
              border-radius: 4px;
              border: 1px solid var(--Input-Outline-Press, rgba(0, 0, 0, 0.38)) !important;
              background: var(--Input-Foreground-Press, rgba(0, 0, 0, 0.08))!important;
              color: var(--custom_color_17) !important;
            }

            &.btn-outline-secondary {
              &.show {
                border-radius: 4px;
                border: 1px solid var(--Input-Outline-Active, #000);
                background: var(--Input-Foreground-Default, rgba(0, 0, 0, 0.00));
                color: var(--custom_color_17) !important;
              }
            }

            &::after {
              display: none !important;
            }
          }
        }

        &-menu {
          overflow-y: auto;
          max-height: 330px;
          position: absolute;
          top: 40px !important;

          &.dropdown-menu {
            width: 100%;
            padding: 6px 6px 8px 6px;
            background: var(--brand_white);

            &.show {
              box-shadow: 0px 4px 8px 0px var(--Shadow-2, rgba(0, 0, 0, 0.12));
              border-radius: 0px 0px 4px 4px;
              border: 1px solid var(--Border-Normal-Default, rgba(0, 0, 0, 0.08));
            }
          }

          &-item {
            padding: 0px 8px;
            &.dropdown-item {
              width: 100%;
              font-size: 14px;
              font-weight: 400;
              line-height: 20px;
              border: none;
              background: initial;
              color: var(--brand_black);
              white-space: nowrap;
              overflow: hidden;
              text-overflow: ellipsis;
              height: 32px;
              align-items: center;
              display: flex;

              &.active {
                background: var(--hGreyscale_88);
                color: var(--brand_black);
                font-weight: 700;
              }

              &:first-child {
                border-top-left-radius: 12px;
                border-top-right-radius: 12px;
              }

              &:last-child {
                border-bottom-left-radius: 12px;
                border-bottom-right-radius: 12px;
              }

              &.selected {
                background: var(--brand_primary) !important;
                border-radius: 4px;
                .searched-text {
                  color: var(--brand_black) !important;
                  font-weight:700;
                }
              }
            }

            &.dropdown-item:hover {
              background: rgba(var(--custom_color_18));
              border-radius: 4px;
            }
          }
          .loader-container {
            text-align: center;
            background-color: var(--brand_white);
            height: 32px;

            .site-spinner {
              padding: 2.5px;
            }
          }


          .menu-container{
            position: relative;
          }
        }

        .area-selected {
          &-wrapper {
            display: inline-flex;
            align-items: center;
          }

          &-name {
            margin-left: 0px !important;
          }
        }
      }
    }

    .right-side {
      display: flex;
      height:  max-content;
      gap: 8px;
      align-items: center;
      font-size: 14px;
      line-height: 20px;
      text-align: left;
      flex-wrap: wrap;

      .search-container {
        display: flex;
        text-align: center;
        align-items: center;
        width: 320px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        align-self: stretch;
        border-radius: 4px;
        border: 1px solid var(--custom_color_16);
        background: initial;
        position: relative;

        & .search-icon {
          z-index: 9;
          margin: 4px 8px 4px 4px;
          position: absolute;
          left: 0;
        }

        & .search-input {
          padding: 6px 8px 6px 32px;
          color: var(--greyscale_00);
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          background: initial;
          width: 310px;
          height: 30px;
          border-radius: none;
          border: none;

          &:hover {
            background: initial !important;
            color: var(--greyscale_40) !important;
            border-color: var(--dark_orange);
          }

          &:focus {
            color: var(--bs-body-color);
            background-color: var(--bs-body-bg);
            border-color: var(--greyscale_buttons_2); // ToDo: Need to Fix
            outline: 0;
          }
        }

        & .search-close {
          position: absolute;
          cursor: pointer;
          right: 0;
          margin: 8px;
        }
      }
    }
  }

  .hide {
    visibility: hidden;
  }

  .grip-drag {
    background-color: var(--brand_primary);
    opacity: 1;
    width: 1px;
    border-color: var(--brand_primary);
  }

  .grip-handle {
    margin-left: -2px;
  }


  /* Table styles */

  .table {
    border-collapse: collapse;
    width: 100%;
    min-width: 1080px !important;
    margin-bottom: 4px;
  }

  .table-responsive {
    justify-content: flex-start;

    .grip-container {
      min-width: 1080px !important;
      width: 100% !important;
    }
  }

  .table thead,
  .table th {
    padding: 0px 0px 0px 10px;
    gap: 10px;
    background: var(--custom_color_22);
    color: var(--text_color);
    align-items: center;
    text-align: start;
    vertical-align: middle;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    .fixed-column {
      width: 29px !important;
    }

    .header-applications-linked {
      width: 241px !important;
    }

    .arrow {
      width: 100%;
      gap: 4px;
      height: 19px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .checkbox {
      width: 100%;
      gap: 4px;
      height: 19px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .deviceName {
      width: 100%;
      gap: 4px;
      height: 19px;
      border-right: 1px solid var(--custom_color_23);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .modelNumber {
      width: 100%;
      gap: 4px;
      height: 19px;
      border-right: 1px solid var(--custom_color_23);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .serialNo {
      width: 100%;
      gap: 4px;
      height: 19px;
      border-right: 1px solid var(--custom_color_23);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .status {
      width: 100%;
      gap: 4px;
      height: 19px;
      border-right: 1px solid var(--custom_color_23);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .location {
      width: 100%;
      gap: 4px;
      height: 19px;
      border-right: 1px solid var(--custom_color_23);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .area {
      width: 100%;
      gap: 4px;
      height: 19px;
      border-right: 1px solid var(--custom_color_23);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .applications-linked {
      width: 100%;
      gap: 4px;
      height: 19px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .ipAddress {
      width: 100%;
      gap: 4px;
      height: 19px;
      border-right: 1px solid var(--custom_color_23);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .createdDate {
      width: 100%;
      gap: 4px;
      height: 19px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .connectionStatus {
      width: 100%;
      gap: 4px;
      height: 19px;
      border-right: 1px solid var(--custom_color_23);
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }


  .table thead,
  .table tbody,
  .table tfoot,
  .table tr,
  .table td,
  .table th {
    text-align: start;
    height: 48px;
    vertical-align: middle;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
  }

  .table {
    thead {
      height: 46px;

      tr,
      th {
        height: 46px;
      }
    }
  }

  thead,
  .table> :not(:first-child) {
    border-top: 0;
  }

  .table thead th {
    color: var(--text_color);
    height: 46px;
    background: var(--custom_color_22);
  }

  .table tbody td {
    border-style: none none none none;
    color: rgba(var(--custom_color_17));
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    padding: 0px 10px 0px 10px;

    .margin-rt {
      margin-right: 8px;
    }

    .margin-lt-20 {
      margin-left: 20px;
    }

  }

  .table tbody tr {
    background: initial;
    height: 48px;
    padding: 0px 20px 0px 12px;
    border-style: solid solid solid solid;
    border: 1px solid var(--custom_color_23);
    cursor: auto;

    .hover-icons {
      visibility: hidden;
    }

    .site-spinner {
      padding: 2px;
    }

    .margin-rt-4 {
      margin-right: 4px;
    }
    .no-device-container {
      text-align: center;
      background-color: var(--brand_white);
      height: calc(100vh - 400px);
      text-align: center;

      .no-device-content {
        display: flex;
        flex-direction: column;
        text-align: center;
        font-family: "Noto Sans";
        font-style: normal;
        padding: 0px;

        .no-device-heading {
          font-size: 16px;
          font-weight: 600;
          line-height: 16px;
          color: var(--brand_black);
        }
        .no-device-text {
          color: rgba(var(--custom_color_28));
          text-align: center;
          font-family: "Noto Sans";
          font-size: 16px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px; /* 137.5% */
        }
      }
    }
     .loader-container {
      text-align: center;
      background-color: var(--brand_white);
      height: calc(100vh - 400px);

      .site-spinner {
        padding: 2.5px;
      }
    }

    &:hover {
      background-color: var(--brand_white);
    }
  }

  .table .row-bg {
    &:hover {
      background-color: rgba(var(--custom_color_18));

      .hover-icons {
        visibility: visible;
      }
    }
  }

  .table thead tr {
    padding: 0px 20px 0px 12px;
  }


  .table tbody td svg {
    width: auto;
    height: auto;
    flex: none;
  }

  .table tr td:first-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    padding-left: 12px;
    padding-right: 2px;
  }

  .table tr td:last-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    padding-right: 20px;
  }

  .table tr th:first-child {
    border-top-left-radius: 8px;
    border-bottom-left-radius: 0px;
    padding-left: 12px;
  }

  .table tr th:last-child {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 0px;
    padding-right: 20px;
  }

  .table tr:first-child td {
    border-top-style: none;
  }

  .table tr td:first-child {
    border-left-style: solid;
  }

  .table .columnheader-asc span,
  .table .columnheader-desc span {
    margin-left: 12px;
  }

  .table .columnheader-desc {
    color: var(--primary_40);
  }

  .table .columnheader-desc svg path {
    stroke: var(--primary_40);
  }

  .table .columnheader-asc {
    color: var(--greyscale_56);
  }

  .table .columnheader-asc svg path {
    stroke: var(--greyscale_56);
  }

  .deviceIcon {
    height: 32px;
    padding: 8px;
    cursor: pointer;
    border: 1px solid var(--border-default);
    margin: 0 8px 0 0;
    border-radius: 4px;
    width: 50px;
  }

  .appActiveOnCloud {
    border: 1px solid var(--primary_40);
    background-color: var(--primary_96);
    cursor: pointer;
  }

  .appActiveWithoutOnCloud {
    cursor: default;
  }

  .appDisableWithoutOnCloud {
    background-color: var(--contrast-c9);
    cursor: default;
  }

  .parent-name {
    color: var(--brand_black) !important;
    font-weight: 600 !important;
  }

  .device-name-color {
    color: var(--brand_black) !important;
  }

  .load-more {
    display: flex;
    height: 32px;
    padding: 3px 20px;
    align-items: center;
    gap: 2px;
    border-radius: 4px;
    border: 1px solid rgba(var(--custom_color_24));
    background: initial;
    color: var(--brand_black);
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
    /* 142.857% */
    width: max-content;
    margin-top: 8px;
    margin-bottom: 8px;

    &:hover {
      background-color: rgba(var(--custom_color_18));
    }

    &:active {
      border: 1px solid rgba(var(--custom_color_19));
      background: rgba(var(--custom_color_26));
    }

    &:disabled {
      border: 1px solid rgba(var(--custom_color_19));
      color: rgba(var(--custom_color_20));
      pointer-events: none;
    }
  }

  .load-more-disabled {
    border: 1px solid rgba(var(--custom_color_19)) !important;
    color: rgba(var(--custom_color_20)) !important;
    pointer-events: none !important;
  }

  .load-more-child {
    margin-left: 40px;
  }

  .load-more-parent {
    margin-left: 12px;
  }

  .paginated-online-device {
    color: var(--custom_color_25) !important;
    font-weight: 500 !important;
  }

  .paginated-deactiavted-device {
    color: var(--hGreyscale_40) !important;
    font-weight: 500 !important;
  }

  .paginated-claiming-device {
    color: var(--brand_primary) !important;
    font-weight: 500 !important;
  }

  .paginated-offline-device {
    color: var(--hGreyscale_40) !important;
    font-weight: 500 !important;
  }

  .expanded-parent-row {
    background-color: rgba(var(--custom_color_18)) !important;
  }

  .disabled-row {
    border-top: 1px solid rgba(var(--custom_color_26));
    opacity: 0.5;
    pointer-events: none;
  }

  .fw-update-icon {
    width: 20px;
    height: 16px;
    border-radius: 4px;
    border: 1px solid var(--brand_primary);
    background: initial;
    color: var(--brand_primary);
    text-align: center;
    font-size: 11px;
    font-style: normal;
    font-weight: 900;
    padding: 0px 2px;
    margin-left: 4px;

    &:hover {
      background-color: var(--brand_primary);
      color: var(--brand_white);
    }
  }

  .cell-flex-container {
    display: flex;
    align-items: center;
    width: 100%;
  }

  .paginated-device-name {
    text-overflow: inherit !important;
  }

  .truncated-device-name {
    display: flex;
    align-items: center !important;
  }

  .cell-tooltip-wrapper {
    min-width: 0;
  }

  .ellipsis-cell {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .searchStyles {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 80%;
  }

  .searched-text {
    color: var(--primary_40);
    font-weight:700;
  }

  .device-icon-width {
    width: 32px !important;
  }

  .cell-checkbox-container {
    display: flex;
    align-items: center;
    width: 16px;
  }

  .delete-checkbox input[type='checkbox'] {
    height: 16px;
    width: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    border: 1.5px solid var(--greyscale_80);
    background: initial;
    appearance: none;
    cursor: pointer;
    position: relative;
    margin-right: 10px;
  }

  .delete-checkbox input[type='checkbox']:disabled {
    height: 16px;
    width: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    border: 1.5px solid var(--greyscale-40);
    background: var(--greyscale_48);
    appearance: none;
    cursor: auto;
    margin-right: 10px;
  }

  .delete-checkbox input[type='checkbox']:checked::after {
    position: absolute;
    width: 16px;
    height: 16px;
    color: var(--brand_white);
    content: '\2713';
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 600;
    border: none;
    border-radius: 2px;
    background: var(--primary_40);
    cursor: pointer;
  }
  .edit-checkbox input[type='checkbox'] {
    height: 16px;
    width: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    border: 1.5px solid var(--greyscale_80);
    background: initial;
    appearance: none;
    cursor: pointer;
    position: relative;
    margin-right: 10px;
  }

  .edit-checkbox input[type='checkbox']:disabled {
    height: 16px;
    width: 16px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 2px;
    border: 1.5px solid var(--greyscale-40);
    background: var(--greyscale_48);
    appearance: none;
    cursor: auto;
    margin-right: 10px;
  }

  .edit-checkbox input[type='checkbox']:checked::after {
    content: '';
    display: block;
    align-items: center;
    justify-content: center;
    background: var(--brand_white);
    cursor: pointer;
    border: 2px solid var(--brand_primary);
    border-radius: 4px;
  }
  .edit-inner-checkbox {
    left: 5px;
    bottom: 5px;
    width: 6px;
    height: 6px;
    position: absolute;
    background-color: var(--brand_primary);
    display: flex;
    border-radius: 1px;
  }
  .edit-checkbox{
    position: relative;
  }

  .edit-name-container {
    width: calc(100% - 54px);
    height: 32px;
    padding: 5px 4px 6px 8px;
    gap: 2px;
    text-align: center;
    flex-direction: row;
    align-items: center;
    align-self: stretch;
    border-radius: 4px;
    border: 1px solid var(--brand_black);
    background: initial;
    position: relative;
    justify-content: space-between;
    margin-top: 0;

    input[type="search"]::-webkit-search-cancel-button {
      -webkit-appearance: none;
      width: 20px;
      height: 20px;
      background: url("data:image/svg+xml,%3Csvg%20width%3D%2216%22%20height%3D%2216%22%20viewBox%3D%220%200%2016%2016%22%20fill%3D%22none%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%3Cpath%20fill-rule%3D%22evenodd%22%20clip-rule%3D%22evenodd%22%20d%3D%22M2%208C2%2011.3137%204.68629%2014%208%2014C11.3137%2014%2014%2011.3137%2014%208C14%204.68629%2011.3137%202%208%202C4.68629%202%202%204.68629%202%208ZM6.36185%2010.5019C6.16659%2010.6972%205.85001%2010.6972%205.65475%2010.5019C5.45949%2010.3067%205.45949%209.99007%205.65475%209.79481L7.42221%208.02734L5.65422%206.25935C5.45896%206.06409%205.45896%205.74751%205.65422%205.55224C5.84948%205.35698%206.16606%205.35698%206.36133%205.55224L8.12932%207.32024L9.89739%205.55217C10.0927%205.35691%2010.4092%205.35691%2010.6045%205.55217C10.7998%205.74743%2010.7998%206.06401%2010.6045%206.25927L8.83643%208.02734L10.604%209.79488C10.7992%209.99015%2010.7992%2010.3067%2010.604%2010.502C10.4087%2010.6973%2010.0921%2010.6973%209.89686%2010.502L8.12932%208.73445L6.36185%2010.5019Z%22%20fill%3D%22%23747E87%22%2F%3E%3C%2Fsvg%3E")
      center center no-repeat;
      cursor: pointer;
    }

    & .cross-icon {
      z-index: 9;
      margin: 7px 4px 4px 16px;
      position: absolute;
      right: 0;
      top: 0;
    }

    & .save-name-icon {
      z-index: 9;
      margin: 4px 8px 4px 16px;
      position: absolute;
      right: 0;
    }

    & .rename-input {
      padding: 0px 1px 1px 0px;
      color: var(--brand_black);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 20px;
      /* 142.857% */
      width: 100%;
      border-radius: none;
      border: none;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      background: initial;

      &:hover {
        background: initial !important;
        color: var(--brand_black) !important;
        border-color: var(--dark_orange);
        outline: 0;
      }

      &:focus {
        background: initial !important;
        color: var(--brand_black) !important;
        outline: 0;
      }
    }

    & .search-close {
      width: 16px;
      height: 16px;
      position: absolute;
      cursor: pointer;
      right: 16px;
    }
    & .renaming {
      width: calc(100% - 16px);
      padding-right: 4px;
    }
  }
  .toggle-container {
    display: flex;
    align-items: center;
    margin-bottom: 9px;
    .claim-selected {
      display: flex;
      height: 32px;
      min-width: 124px;
      padding: 0px 12px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 4px 0px 0px 4px;
      background: var(--Button-Secondary-Default, rgba(0, 0, 0, 0.14));
      color: var(--brand_black);
      text-align: center;
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height:20px; /* 142.857% */
    }
    .claim-unselected {
      display: flex;
      height: 32px;
      min-width: 124px;
      padding: 0px 12px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 4px 0px 0px 4px;
      border-top: 1px solid var(--Button-Tertiary-Outline-Default, rgba(0, 0, 0, 0.21));
      border-left: 1px solid var(--Button-Tertiary-Outline-Default, rgba(0, 0, 0, 0.21));
      border-bottom: 1px solid var(--Button-Tertiary-Outline-Default, rgba(0, 0, 0, 0.21));
      color: rgba(var(--custom_color_32));
      text-align: center;
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height:20px; /* 142.857% */
    }
    .reclaim-selected {
      display: flex;
      height: 32px;
      min-width: 124px;
      padding: 0px 12px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 0px 4px 4px 0px;
      background: var(--Button-Secondary-Default, rgba(0, 0, 0, 0.14));
      color: var(--brand_black);
      text-align: center;
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height:20px; /* 142.857% */
    }
    .reclaim-unselected {
      display: flex;
      height: 32px;
      min-width: 124px;
      padding: 0px 12px;
      justify-content: center;
      align-items: center;
      gap: 4px;
      border-radius: 0px 4px 4px 0px;
      border-top: 1px solid var(--Button-Tertiary-Outline-Default, rgba(0, 0, 0, 0.21));
      border-right: 1px solid var(--Button-Tertiary-Outline-Default, rgba(0, 0, 0, 0.21));
      border-bottom: 1px solid var(--Button-Tertiary-Outline-Default, rgba(0, 0, 0, 0.21));
      color: rgba(var(--custom_color_32));
      text-align: center;
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height:20px; /* 142.857% */
    }
  }
  .pagination-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 20px;
    align-self: stretch;
    margin-top: 16px;
    flex-wrap: wrap;

    .go-to-page {
      color: rgba(var(--custom_color_17));
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 10px;

      .input-page {
        display: flex;
        height: 32px;
        align-items: center;
        gap: 8px;
        border-radius: 4px;
        border: 1px solid rgba(var(--custom_color_19));
        background: var(--brand_white);
        & .page-input {
          padding: 0px 8px;
          color: rgba(var(--custom_color_17));
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 20px;
          background: initial;
          width: 100%;
          max-width: 50px;
          min-width: 50px;
          height: 30px;
          border-radius: none;
          border: none;

          &:hover {
            background: initial !important;
            color: var(--greyscale_40) !important;
            border-color: var(--dark_orange);
          }

          &:focus {
            color: var(--bs-body-color);
            background-color: var(--bs-body-bg);
            border-color: var(--greyscale_buttons_2); // ToDo: Need to Fix
            outline: 0;
          }
        }
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type=number] {
          -moz-appearance: textfield;
        }
      }
      .Mui-selected {
          background-color: var(--brand_primary)
      }
    }
    .total {
      color:  rgba(var(--custom_color_17));
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      display: flex;
      gap: 4px;
    }
    .Mui-selected {
      background-color: var(--brand_primary);
      color: var(--brand_white) !important;
      &:hover{
        background-color: var(--dark_orange_1);
        color: var(--brand_white);
      }
    }
    .MuiPaginationItem-rounded {
      display: flex;
      min-width: 24px;
      height: 24px;
      padding: 3px 0px;
      justify-content: center;
      align-items: center;
      border-radius: 4px;
      color:  rgba(var(--custom_color_17));
      text-align: center;
      font-family: "Noto Sans";
      font-size: 12px;
      font-style: normal;
      font-weight: 400;
      line-height: 18px;
    }
  }
  .update-location-usermsg-container {
    display: flex;
    gap: 4px;
    align-items: center;
    flex-wrap: wrap;

    .success {
      color: var(--custom_color_25);
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }
    .failure{
      color: var(--brand_black);
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }
}

.paginated-device-page {
  width: 100%;
  justify-content: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.loader-style {
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: center;
  color: var(--greyscale_56);
  top: 55%;
}
._loading_overlay_overlay{
  background: rgba(0, 0, 0, 0.01);
}
.screen-loader-container {
  text-align: center;
  background-color: var(--brand_white);
  height: 0px;

  .site-spinner {
    padding: 2.5px;
  }
}

.location-area-selector-modal {
  .modal-dialog.modal-dialog-centered {
    display: flex;
    justify-content: center;
  }

  .modal-dialog .modal-content {
    width: 545px;
    min-width: 545px;
    padding: 16px 24px 24px 24px !important;
  }

  .location-area-selector-modal-wrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    overflow: scroll;

    .modal-title {
      color: var(--brand_black);
      /* 14 regular */
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
      margin-top: 20px;
    }

    .dropdown-title {
      color: var(--brand_black);

      /* 14 semi bold */
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
      margin-top: 20px;
    }

    .dropdown-container {
      display: flex;
      padding: 20px;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;
      align-self: stretch;
      border-radius: 12px;
      background: rgba(var(--custom_color_18));
      margin-top: 10px;
      width: 100%;

      .dropdown-row {
        display: flex;
        width: 300px;
        height: 48px;
        justify-content: space-between;
        align-items: center;
        color: rgba(var(--custom_color_17));
        font-family: "Noto Sans";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        width: 100%;

        .area-selector {
          width: 300px;
          padding: 0px;
          border: none;

          &-toggle {
            &.dropdown-toggle {
              display: flex;
              padding: 6px 4px 8px 8px;
              flex-direction: row;
              align-items: flex-start;
              justify-content: space-between;
              gap: 8px;
              align-self: stretch;
              width: 100%;
              border: 1px solid var(--Input-Outline-Default, rgba(0, 0, 0, 0.21));
              background: var(--brand_white) !important;
              border-radius: 4px !important;
              color: var(--custom_color_17);
              font-family: 'Noto Sans';
              font-size: 14px !important;
              font-weight: 400;
              line-height: 20px !important;
              width: 300px;
              height: 32px;
              min-width: 300px;

              &:hover {
                border-radius: 4px;
                border: 1px solid var(--Input-Outline-Hover, rgba(0, 0, 0, 0.29)) !important;
                background: var(--Input-Foreground-Hover, rgba(0, 0, 0, 0.04)) !important;
                color: var(--custom_color_17) !important;
              }

              &:active {
                border-radius: 4px;
                border: 1px solid var(--Input-Outline-Press, rgba(0, 0, 0, 0.38)) !important;
                background: var(--Input-Foreground-Press, rgba(0, 0, 0, 0.08))!important;
                color: var(--custom_color_17) !important;
              }

              &.btn-outline-secondary {
                &.show {
                  border-radius: 4px;
                  border: 1px solid var(--Input-Outline-Active, #000) !important;
                  background: var(--brand_white) !important;
                  color: var(--custom_color_17) !important;
                }
              }

              &::after {
                display: none !important;
              }
            }
          }

          &-menu {
            overflow-y: auto;
            max-height: 80px;

            &.dropdown-menu {
              width: 100%;
              padding: 8px 6px;
              background: var(--brand_white);

              &.show {
                box-shadow: 0px 10px 15px -3px rgba(var(--greyscale-custom1), 0.1),
                0px 4px 6px -2px rgba(var(--greyscale-custom1), 0.05);
                border-radius: 4px;
              }
            }

            &-item {
              &.dropdown-item {
                width: 100%;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                border: none;
                background: var(--brand_white);
                color: var(--brand_black) !important;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                &.active {
                  background: var(--hGreyscale_88);
                  color: var(--brand_black);
                  font-weight: 700;
                }

                &:first-child {
                  border-top-left-radius: 12px;
                  border-top-right-radius: 12px;
                }

                &:last-child {
                  border-bottom-left-radius: 12px;
                  border-bottom-right-radius: 12px;
                }

                &.selected {
                  background: var(--brand_primary) !important;
                  border-radius: 4px;
                }
              }

              &.dropdown-item:hover {
                background: rgba(var(--custom_color_18));
                border-radius: 4px;
              }
            }
          }

          .area-selected {
            &-wrapper {
              display: inline-flex;
              align-items: center;
            }

            &-name {
              margin-left: 0px !important;
            }
          }
        }
        .searched-text {
          color: var(--primary_40);
          font-weight:700;
        }

        .searchable-selector {
          width: 300px;
          height: 31px;
          .drop-down-container{
            background: var(--brand_white);
            position: fixed;
            width: 300px;
            z-index: 99999;
          }
          .default-container {
            height: calc(30vh - 86px);
            align-items: center;
            justify-content: center;
            display: flex;

            color: var(--Text-Normal-Secondary, rgba(0, 0, 0, 0.77));
            text-align: center;
            font-family: "Noto Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 16px;

            .no-locations-container{
              display: flex;
              width: 162px;
              flex-direction: column;
              align-items: center;
              gap: 4px;
            }
            .site-spinner {
              padding: 2.5px;
            }
          }
          .search-container {
            text-align: center;
            align-items: center;
            width: 100%;
            display: flex;
            flex-direction: column;
            background: inherit;
            padding: 10px;
            height: 40px;
            position: absolute;
            top: 5px;
            z-index: 9999;
            border-radius: 4px 4px 0px 0px;
            border: 1px solid var(--Border-Normal-Default, rgba(0, 0, 0, 0.08));

            & .search-icon {
              z-index: 9;
              margin: 0px 8px 0px 8px;
              position: absolute;
              left: 0;
            }

            & .search-input {
              padding: 0px 20px 0px 24px;
              color: var(--greyscale_00);
              font-size: 14px;
              font-style: normal;
              font-weight: 400;
              line-height: 20px;
              background: initial;
              width: 100%;
              display: flex;
              align-items: center;
              border: none;

              &:hover {
                background: initial !important;
                color: var(--greyscale_00) !important;
                border-color: var(--dark_orange);
              }

              &:focus {
                color: var(--greyscale_00);
                background-color: var(--bs-body-bg);
                border-color: var(--greyscale_buttons_2); // ToDo: Need to Fix
                outline: 0;
              }
            }

            & .search-close {
              position: absolute;
              cursor: pointer;
              right: 0;
              margin: 2px 10px 0px 0px;
            }
          }

          &-toggle {
            &.dropdown-toggle {
              display: flex;
              padding: 6px 8px;
              flex-direction: row;
              align-items: flex-start;
              justify-content: space-between;
              gap: 8px;
              align-self: stretch;
              width: 100%;
              border: 1px solid var(--Input-Outline-Default, rgba(0, 0, 0, 0.21));
              background: var(--brand_white);
              border-radius: 4px !important;
              color: var(--custom_color_17);
              font-family: "Noto Sans";
              font-size: 14px !important;
              font-weight: 400;
              line-height: 20px !important;
              max-width: 300px;
              min-width: 300px;
              height: 32px;

              &:hover {
                border-radius: 4px;
                border: 1px solid var(--Input-Outline-Hover, rgba(0, 0, 0, 0.29)) !important;
                background: var(--Input-Foreground-Hover, rgba(0, 0, 0, 0.04)) !important;
                color: var(--custom_color_17) !important;
              }

              &:active {
                border-radius: 4px;
                border: 1px solid var(--Input-Outline-Press, rgba(0, 0, 0, 0.38)) !important;
                background: var(--Input-Foreground-Press, rgba(0, 0, 0, 0.08))!important;
                color: var(--custom_color_17) !important;
              }

              &.btn-outline-secondary {
                &.show {
                  border-radius: 4px;
                  border: 1px solid var(--Input-Outline-Active, #000);
                  background: var(--brand_white);
                  color: var(--custom_color_17) !important;
                }
              }

              &::after {
                display: none !important;
              }
            }
          }

          &-menu {
            overflow-y: auto;
            max-height: calc(30vh - 70px);
            margin-top: 40px;

            &.dropdown-menu {
              width: 100%;
              padding: 6px 6px 8px 6px;
              background: var(--brand_white);

              &.show {
                box-shadow: 0px 4px 8px 0px var(--Shadow-2, rgba(0, 0, 0, 0.12));
                border-radius: 0px 0px 4px 4px;
                border: 1px solid var(--Border-Normal-Default, rgba(0, 0, 0, 0.08));
              }
            }

            &-item {
              padding: 0px 8px;
              &.dropdown-item {
                width: 100%;
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                border: none;
                background: initial;
                color: var(--brand_black) !important;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                height: 32px;
                align-items: center;
                display: flex;

                &.active {
                  background: var(--hGreyscale_88);
                  color: var(--brand_black);
                  font-weight: 700;
                }

                &:first-child {
                  border-top-left-radius: 12px;
                  border-top-right-radius: 12px;
                }

                &:last-child {
                  border-bottom-left-radius: 12px;
                  border-bottom-right-radius: 12px;
                }

                &.selected {
                  background: var(--brand_primary) !important;
                  border-radius: 4px;
                  .searched-text {
                    color: var(--brand_black) !important;
                    font-weight:700;
                  }
                }
              }

              &.dropdown-item:hover {
                background: rgba(var(--custom_color_18));
                border-radius: 4px;
              }
            }
            .loader-container {
              text-align: center;
              background-color: var(--brand_white);
              height: 32px;

              .site-spinner {
                padding: 2.5px;
              }
            }


            .menu-container{
              position: relative;
            }
          }

          .area-selected {
            &-wrapper {
              display: inline-flex;
              align-items: center;
            }

            &-name {
              margin-left: 0px !important;
            }
          }
        }
      }

      .row-border {
        border-top: 1px solid rgba(var(--custom_color_26));
      }
    }

    .button-container {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      gap: 8px;
      width: 100%;
      margin-top: 24px;
    }

    .css-90a3vc-MuiButtonBase-root-MuiRadio-root {
      padding: 0px;
    }

    .css-mkl144 {
      padding: 0px;
    }

    .css-mkl144.Mui-checked {
      padding: 0px;
      color: var(--primary_40) !important;
    }

    .MuiFormGroup-root .MuiRadio-root {
      padding: 0px;

      &.Mui-checked {
        color: var(--primary_40);
      }
    }

    .device-setup-modal-subTitle {
      color: var(--brand_black);
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: normal;
    }

    .discription {
      color: var(--C-2-61, rgba(0, 0, 0, 0.61));
      /* 14 regular */
      font-family: "Noto Sans";
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

}

.discovered-device-filter-modal {
  .modal-dialog.modal-md.modal-dialog-centered {
    display: flex;
    justify-content: center;
  }

  .modal-dialog.modal-md.modal-dialog-centered .modal-content {
    padding: 24px !important;
  }

  .modal-dialog .modal-content {
    width: 485px;
    min-width: 485px;
    padding: 24px !important;

    .modal-body {
      padding-bottom: 0;

      .device-filter-modal-wrapper {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        justify-content: center;
      }

      .filter-checkbox input[type='checkbox'] {
        height: 14px;
        width: 14px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        border: 1.5px solid var(--greyscale_80);
        background: var(--brand_white);
        appearance: none;
        cursor: pointer;
        position: relative;
      }

      .filter-checkbox input[type='checkbox']:disabled {
        height: 14px;
        width: 14px;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 2px;
        border: 1.5px solid var(--greyscale-40);
        background: var(--greyscale_48);
        appearance: none;
        cursor: auto;
      }

      .filter-checkbox input[type='checkbox']:checked::after {
        position: absolute;
        width: 14px;
        height: 14px;
        color: var(--brand_white);
        content: '\2713';
        display: flex;
        align-items: center;
        justify-content: center;
        font-weight: 600;
        border: none;
        border-radius: 2px;
        background: var(--primary_40);
        cursor: pointer;
      }

      .filter-table-structure {
        display: flex;
        align-items: flex-start;
        gap: 13px;

        .filter-column {
          display: flex;
          width: 212px;
          flex-direction: column;
          align-items: flex-start;
          gap: 15px;

          .hide {
            visibility: hidden;
          }

          .filter-container {
            display: flex;
            width: 212px;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
          }

          .filter-row-container {
            display: flex;
            height: 32px;
            padding: 0px 8px;
            align-items: center;
            gap: 8px;
            align-self: stretch;
            color: rgba(var(--custom_color_17));
            font-family: "Noto Sans";
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            width: max-content;
          }

          .filter-header {
            color: var(--greyscale_00);
            font-family: "Noto Sans";
            font-size: 16px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
          }
        }

        .filter-column-border {
          border-right: 1px solid rgba(var(--custom_color_27));
        }
      }

      .filter-footer {
        padding: 20px 8px 0px 8px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 100%;

        .left-section {
          display: flex;
          flex-direction: row;
          align-items: flex-start;
          color: rgba(var(--custom_color_28));
          text-align: center;
          font-family: "Noto Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
          align-items: center;
          gap: 4px;

          .rotate-reload {
            transform: rotateX(180deg);
          }
        }

        .right-section {
          display: flex;
          flex-direction: row;
          align-items: flex-end;
          gap: 8px;
        }
      }
    }
  }
}

.discovered-device-date-modal {
  .modal-dialog.modal-lg.modal-dialog-centered {
    display: flex;
    justify-content: center;
  }

  .modal-dialog .modal-content {
    width: 316px;
    min-width: 316px;
    padding: 16px 20px 20px 20px !important;

    .modal-body {
      padding-bottom: 0;
      max-height: 100%;

      .date-time-picker {
        &.dark {
          .custom-calendar.rmdp-wrapper {
            .rmdp-calendar {
              .rmdp-header {
                margin-bottom: 6px !important;
                margin-top: 0px !important;

                &.disabled {
                  &:hover {
                    background-color: var(--brand_primary) !important;
                  }
                }
              }
            }
          }
        }

        .date-time-picker-main-range {
          .timeline-calendar.rmdp-wrapper {
            .rmdp-calendar .rmdp-header {
              .rmdp-header-values {

                >span:first-child,
                span:last-child {
                  background-color: var(--brand_white) !important;
                  color: var(--brand_black) !important;
                  padding: 2px 0px 3px !important;
                }
              }
            }
          }
        }

        .rmdp-calendar-range .rmdp-header {
          .rmdp-header-values {

            >span:first-child,
            span:last-child {
              background-color: var(--brand_white) !important;
              color: var(--brand_black) !important;
              padding: 2px 10px 3px !important;
            }
          }
        }

        .date-time-picker-main {
          .timeline-calendar.rmdp-wrapper {
            .rmdp-calendar .rmdp-header {
              .rmdp-header-values {

                >span:first-child,
                span:last-child {
                  background-color: var(--primary_96) !important;
                  color: var(--primary_32) !important;
                  padding: 2px 10px 3px !important;
                  border-radius: 4px !important;
                }
              }
            }
          }
        }

        .select-time-label {
          color: var(--greyscale_08);
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 20px;
          /* 125% */
          margin-top: 24px;
          margin-bottom: 8px;
        }

        .custom-calendar.rmdp-wrapper {
          width: 100%;
          border: none;

          .rmdp-calendar {
            width: 100%;
            padding: 0px;

            .rmdp-day-picker,
            .rmdp-month-picker,
            .rmdp-year-picker {
              width: 100%;
              padding: 0px;

              >div {
                width: 100%;
              }

              .rmdp-week,
              .rmdp-ym {
                width: 100%;

                .rmdp-week-day {
                  color: var(--greyscale_40);
                  text-align: center;
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 600;
                  line-height: 16px;
                  /* 133.333% */
                  text-transform: uppercase;
                  display: flex;
                  width: 42px;
                  height: 18px;
                  padding: 0px 5px 2px 6px;
                  justify-content: center;
                  align-items: center;
                }

                .rmdp-day {
                  width: 32px;
                  height: 32px;
                  padding: 5px 0px 7px 0px;

                  &:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
                    background-color: var(--primary_48);
                  }
                }

                .rmdp-selected {
                  >span {
                    &:not(.highlight) {
                      background-color: var(--primary_40);
                    }
                  }
                }
              }
            }

            .rmdp-header {
              width: 100%;
              height: 28px;
              color: var(--brand_black);
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px;
              margin-top: 0px;
              display: flex;
              align-items: center;
              justify-content: center;
              margin-top: 0px;

              /* 142.857% */
              .rmdp-header-values {
                margin-left: 0px;

                >span {
                  padding: 0px;
                }

                >span:first-child,
                span:last-child {
                  background-color: var(--primary_96);
                  color: var(--primary_32);
                  padding: 2px 10px 3px;
                  border-radius: 4px;
                }

                >span:last-child {
                  margin-left: 5px;
                }
              }

              .rmdp-arrow-container {
                .rmdp-arrow {
                  width: 9.971px;
                  height: 11.324px;
                  flex-shrink: 0;
                  border-color: var(--brand_primary);
                }

                &:hover {
                  box-shadow: none;
                  background-color: var(--brand_primary);

                  .rmdp-arrow {
                    border-color: var(--brand_white);
                  }
                }
              }

              .disabled {
                .rmdp-arrow {
                  border-color: var(--primary_80);
                }

                &:hover {
                  border-color: var(--brand_white) !important;
                  background-color: var(--brand_white) !important;
                }
              }
            }
          }
        }

        .custom-calendar-range.rmdp-wrapper {
          width: 100%;
          border: none;

          .rmdp-calendar {
            width: 100%;
            padding: 0px;

            .rmdp-day-picker,
            .rmdp-month-picker,
            .rmdp-year-picker {
              width: 100%;
              padding: 0px;

              >div {
                width: 100%;
              }

              .rmdp-week {
                margin-top: 0px;
                margin-bottom: 4px;
              }

              .rmdp-week:first-child {
                rmdp-week-day span {
                  color: var(--custom_color_29);
                }

                rmdp-day span {
                  color: var(--custom_color_29);
                }
              }

              .rmdp-week,
              .rmdp-ym {
                width: 100%;

                .rmdp-week-day {
                  display: flex;
                  width: 40px;
                  height: 20px;
                  flex-direction: column;
                  justify-content: center;
                  color: var(--custom_color_30);
                  text-align: center;
                  font-family: "Noto Sans";
                  font-size: 12px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  margin-bottom: 4px;
                }

                .rmdp-day {
                  display: flex;
                  width: 40px;
                  height: 28px;
                  flex-direction: column;
                  justify-content: center;
                  color: var(--greyscale_00);
                  text-align: center;
                  font-family: "Noto Sans";
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;

                  &:not(.rmdp-disabled):not(.rmdp-day-hidden) span:hover {
                    background-color: var(--primary_48);
                  }
                }

                .rmdp-day span {
                  font-weight: 600;
                  bottom: 0px;
                  right: 0px;
                  left: 0px;
                  top: 0px;
                  border-radius: 8px;
                }

                .rmdp-selected {
                  >span {
                    &:not(.highlight) {
                      background-color: var(--primary_40);
                    }
                  }
                }

                .rmdp-day.rmdp-today span {
                  display: flex;
                  width: 100%;
                  height: 28px;
                  flex-direction: column;
                  justify-content: center;
                  color: var(--greyscale_00);
                  border: 1.5px solid var(--greyscale_00);
                  border-radius: 8px;
                  text-align: center;
                  font-family: "Noto Sans";
                  font-size: 14px;
                  font-style: normal;
                  font-weight: 400;
                  line-height: normal;
                  background-color: var(--brand_white);
                  margin-left: 6px;
                }

              }
            }

            .rmdp-year-picker,
            .rmdp-month-picker {
              left : 0;
              .rmdp-ym {
                .rmdp-today span {
                  margin-left: 0px !important;
                }
              }
            }

            .rmdp-header {
              width: 100%;
              color: var(--brand_black);
              font-size: 14px;
              font-style: normal;
              font-weight: 600;
              line-height: 20px;
              margin-bottom: 16px;

              /* 142.857% */
              .rmdp-header-values {
                margin-left: 6px;
                display: flex;

                >span {
                  padding: 0px;
                }

                >span:first-child,
                span:last-child {
                  // background-color: var(--primary_96);
                  // color: var(--primary_32);
                  padding: 2px 6px 3px;
                  border-radius: 4px;
                }

                >span:last-child {
                  // margin-left: 5px;
                }
              }

              .rmdp-arrow-container {
                .rmdp-arrow {
                  width: 9.971px;
                  height: 11.324px;
                  flex-shrink: 0;
                  border-color: var(--brand_primary);
                }

                &:hover {
                  box-shadow: none;
                  background-color: var(--brand_primary);

                  .rmdp-arrow {
                    border-color: var(--brand_white);
                  }
                }
              }

              .disabled {
                .rmdp-arrow {
                  border-color: var(--primary_80);
                }

                &:hover {
                  border-color: var(--brand_white) !important;
                  background-color: var(--brand_white) !important;
                }
              }
            }

            .rmdp-range {
              background: rgba(0, 0, 0, 0.08);
              box-shadow: none;
            }

            .rmdp-range.start .sd {
              background-color: var(--primary_40) !important;
              color: var(--brand_white) !important;
              border-bottom-right-radius: 8px;
              border-top-right-radius: 8px;
              border-bottom-left-radius: 8px;
              border-top-left-radius: 8px;

            }

            .rmdp-range.end .sd {
              background-color: var(--primary_40) !important;
              color: var(--brand_white) !important;
              border-bottom-right-radius: 8px;
              border-top-right-radius: 8px;
              border-bottom-left-radius: 8px;
              border-top-left-radius: 8px;


            }

            .rmdp-range.start {
              border-bottom-left-radius: 8px;
              border-top-left-radius: 8px;
            }

            .rmdp-range.end {
              border-bottom-right-radius: 8px;
              border-top-right-radius: 8px;
            }
          }
        }

        .custom-calendar-range-same.rmdp-wrapper {
          .rmdp-range {
            background: initial !important;
          }
        }

        .time-container {
          display: flex;
          width: 100%;
          height: 44px;
          padding: 12px 16px;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;
          border-radius: 12px;
          border: 1.5px solid var(--greyscale_88);
          background: var(--brand_white);
          margin-bottom: 1px;
          cursor: pointer;
        }

        .custom-timepicker.rmdp-wrapper {
          margin-left: -16px;
          margin-top: -18px;
          width: 169.5%;
        }

        .custom-timepicker.rmdp-shadow {
          box-shadow: none;
        }

        .custom-timepicker.ep-arrow {
          visibility: hidden !important;
        }

        .rmdp-container {
          width: 100%;
          align-items: center;
        }

        .custom-timepicker.rmdp-wrapper,
        .rmdp-input {
          border: none;
          box-shadow: none;
          color: var(--greyscale_08);
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 20px;
          /* 142.857% */
          // width: 169.5%;
          height: 98%;
          caret-color: transparent;
          cursor: pointer;
        }

        .rmdp-time-picker.bottom {
          div {
            input {
              caret-color: auto;
            }
          }
        }

        .custom-timepicker.rmdp-wrapper {
          // border: 0.5px solid var(--greyscale_88);
          // border-radius: 12px;
        }
      }

      .button-container {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        margin-top: 36px;

        .today {
          display: flex;
          width: 63px;
          height: 32px;
          padding: 0px 12px;
          justify-content: center;
          align-items: center;
          gap: 4px;
          flex-shrink: 0;
          color: var(--custom_color_30);
          text-align: center;
          font-family: "Noto Sans";
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: normal;
        }
      }
    }
  }
}
